import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../../assets/style/ExtensionActivities.css';

const ActivityCard = ({ activity }) => {
    console.log(activity); // Debugging line

    if (!activity) {
      return <p>Activity data is not available.</p>;
    }

    return (
      <div className="activity-card">
        <img src={activity.imageurl} alt={activity.title} className="activity-card_image" />
        <div className="activity-card_content">
          <h2 className="activity-card_title">{activity.title}</h2>
          <p className="activity-card_date">{activity.date}</p>
          <p className="activity-card_description">{activity.description}</p>
          <p className="activity-card_id">ID: {activity.id}</p> {/* Displaying ID */}
          <Link to={`/extension/activitycard/${activity.id}`} className="activity-card_link">Learn More</Link>
        </div>
      </div>
    );
};

ActivityCard.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    date: PropTypes.string,
    description: PropTypes.string,
    imageurl: PropTypes.string
  })
};

ActivityCard.defaultProps = {
  activity: {
    id: 0,
    title: 'No Title',
    date: 'No Date',
    description: 'No Description',
    imageurl: 'https://via.placeholder.com/150' // Placeholder image
  }
};

export default ActivityCard;
