import React from 'react';
import { Accordion, Table} from 'react-bootstrap';
import '../../../../assets/style/Admission.css';

function Medicine() {
  return (
    <>
    <div className='admission-container'>
        <div className='ad-header'>
            <h1><b>COLLEGE OF MEDICINE</b></h1>
            <hr class="my-1"></hr>
        </div>
        <div className='admission-req'>
            <h3><b>ADMISSION REQUIREMENTS</b></h3>
            <p>The selection of students for admission is the responsibility of Admissions Committee which considers the qualifications of applicants.  Admission is based upon the evaluation of the National Medical Admission Test (NMAT) and/or entrance examination, academic performance (transcript of records), personal interview and health report.</p>
            <h6>Pre-requisites:</h6>
            <p>1. A Bachelor’s degree in any field of interest granted by an accredited academic institution, provided that the course of study included*:</p>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                    <Accordion.Header><b>COURSES REQUIRED</b></Accordion.Header>
                    <Accordion.Body>
                    <Table class="table" size="sm">
                    <thead>
                        <tr>
                            <th scope="col" >Course</th>
                            <th scope="col" >Units</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A. Natural Science</td>
                            <td>30.00</td>
                        </tr>
                        <tr>
                            <td>1. Gen. & Inorganic Chemistry</td>
                            <td>5.00</td>
                        </tr>
                        <tr>
                            <td>2. Organic Chemistry</td>
                            <td>5.00</td>
                        </tr>
                        <tr>
                            <td>3. College Physics</td>
                            <td>5.00</td>
                        </tr>
                        <tr>
                            <td>4. General Zoology</td>
                            <td>5.00</td>
                        </tr>
                        <tr>
                            <td>5. General Botany</td>
                            <td>5.00</td>
                        </tr>
                        <tr>
                            <td>6. Comparative & Vert. Anatomy (or Anatomy and Physiology)</td>
                            <td>5.00</td>
                        </tr>
                        <tr>
                            <td>4. General Zoology</td>
                            <td>5.00</td>
                        </tr>
                        <tr>
                            <td>B. Mathematics</td>
                            <td>9.00</td>
                        </tr>
                        <tr>
                            <td>1. Algebra</td>
                            <td>3.00</td>
                        </tr>
                        <tr>
                            <td>2. Trigonometry</td>
                            <td>3.00</td>
                        </tr>
                        <tr>
                            <td>3. Statistics</td>
                            <td>3.00</td>
                        </tr>
                    </tbody>
                    </Table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p>2. Of good moral character, never been convicted of any crime, good academic record, pleasing personality and attitude fit for medical career.</p>
            <p>3. Must have obtained at least 45% file rating in the National Medical Admission Test (NMAT) taken within the last two (2) years.</p>
            <p>4. Must not have been dropped from the rolls of the RTRMF College of Medicine or other medical schools.</p>
            <p>5. Physically and mentally fit to meet the demands and rigors of medical course</p>
            <p>6. A Certificate of Eligibility for Admission into the Medical Course (CEM) issued by the Commission on Higher Education.</p>
        </div>
        <div className="subhead-ad">
            <hr class="my-5"></hr>
            <h4><b>CURRICULUM</b></h4>
        </div>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FIRST YEAR</Accordion.Header>
                <Accordion.Body>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th>Subjects</th>
                        <th scope="col" >1st Semester</th>
                        <th scope="col" >Second Semester</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Gross Clinical Anatomy</td>
                        <td>180 hrs</td>
                        <td>180 hrs</td>
                    </tr>
                    <tr>
                        <td>Radiographic Anatomy</td>
                        <td>-</td>
                        <td>36 hrs</td>
                    </tr>
                    <tr>
                        <td>Embryology</td>
                        <td>18 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Biochemistry and Human Nutrition</td>
                        <td>162 hrs</td>
                        <td>162 hrs</td>
                    </tr>
                    <tr>
                        <td>Physiology</td>
                        <td>144 hrs</td>
                        <td>144 hrs</td>
                    </tr>
                    <tr>
                        <td>Histology</td>
                        <td>72 hrs</td>
                        <td>72 hrs</td>
                    </tr>
                    <tr>
                        <td>Neuro-Anatomy</td>
                        <td>36 hrs</td>
                        <td>36 hrs</td>
                    </tr>
                    <tr>
                        <td>Psychiatry</td>
                        <td>18 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Perspective in Med & History of Medicine</td>
                        <td>18 hrs</td>
                        <td>18 hrs</td>
                    </tr>
                    <tr>
                        <td>Public Health & Community Med I</td>
                        <td>54 hrs</td>
                        <td>54 hrs</td>
                    </tr>
                    <tr>
                        <td>Research I</td>
                        <td>36 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td>738 hrs</td>
                        <td>702 hrs</td>
                    </tr>
                </tbody>
                </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SECOND YEAR</Accordion.Header>
                <Accordion.Body>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th>Subjects</th>
                        <th scope="col" >1st Semester</th>
                        <th scope="col" >Second Semester</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>General & Systemic Pathology</td>
                        <td>144 hrs</td>
                        <td>144 hrs</td>
                    </tr>
                    <tr>
                        <td>Medicine</td>
                        <td>108 hrs</td>
                        <td>108 hrs</td>
                    </tr>
                    <tr>
                        <td>Pharmacology & Therapeutics</td>
                        <td>108 hrs</td>
                        <td>108 hrs</td>
                    </tr>
                    <tr>
                        <td>Surgery 1</td>
                        <td>72 hrs</td>
                        <td>36 hrs</td>
                    </tr>
                    <tr>
                        <td>Microbiology</td>
                        <td>180 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Preventive & Community Med 2</td>
                        <td>54 hrs</td>
                        <td>36 hrs</td>
                    </tr>
                    <tr>
                        <td>Pediatrics 1</td>
                        <td>36 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Medical Ethics 1</td>
                        <td>18 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Parasitology</td>
                        <td>-</td>
                        <td>126 hrs</td>
                    </tr>
                    <tr>
                        <td>Clinical Pathology 1</td>
                        <td>-</td>
                        <td>72 hrs</td>
                    </tr>
                    <tr>
                        <td>Research 2</td>
                        <td>-</td>
                        <td>36 hrs</td>
                    </tr>
                    <tr>
                        <td>Psychiatry 2</td>
                        <td>-</td>
                        <td>36 hrs</td>
                    </tr>
                    <tr>
                        <td>Obstetrics 1</td>
                        <td>-</td>
                        <td>36 hrs</td>
                    </tr>
                    <tr>
                        <td>Medical Ethics 2 (Medico Moral Problems)</td>
                        <td>-</td>
                        <td>18 hrs</td>
                    </tr>
                    <tr>
                        <td>Medical Economics</td>
                        <td>-</td>
                        <td>18 hrs</td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td>720 hrs</td>
                        <td>774 hrs</td>
                    </tr>
                </tbody>
                </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>THIRD YEAR</Accordion.Header>
                <Accordion.Body>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th>Subjects</th>
                        <th scope="col" >1st Semester</th>
                        <th scope="col" >Second Semester</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Medicine 2 (including CPC)</td>
                        <td>144 hrs</td>
                        <td>144 hrs</td>
                    </tr>
                    <tr>
                        <td>Surgery</td>
                        <td>110 hrs</td>
                        <td>127 hrs</td>
                    </tr>
                    <tr>
                        <td>Pediatrics 2</td>
                        <td>102 hrs</td>
                        <td>110 hrs</td>
                    </tr>
                    <tr>
                        <td>Preventive & Community Med 3</td>
                        <td>51 hrs</td>
                        <td>51 hrs</td>
                    </tr>
                    <tr>
                        <td>Surgical Pathology</td>
                        <td>51 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Ophthalmology</td>
                        <td>51 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Clinical Neurology</td>
                        <td>51 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Radiology</td>
                        <td>51 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Anesthesia</td>
                        <td>34</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Research 3</td>
                        <td>34 hrs</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Gynecology</td>
                        <td>-</td>
                        <td>51 hrs</td>
                    </tr>
                    <tr>
                        <td>OB Clinics</td>
                        <td>-</td>
                        <td>51 hrs</td>
                    </tr>
                    <tr>
                        <td>Otorhinolaryngology</td>
                        <td>-</td>
                        <td>51 hrs</td>
                    </tr>
                    <tr>
                        <td>Psychiatry 3</td>
                        <td>-</td>
                        <td>51 hrs</td>
                    </tr>
                    <tr>
                        <td>Legal Medicine</td>
                        <td>-</td>
                        <td>51 hrs</td>
                    </tr>
                    <tr>
                        <td>Physical & Rehab. Med.</td>
                        <td>-</td>
                        <td>54 hrs</td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td>730 hrs</td>
                        <td>713 hrs</td>
                    </tr>
                </tbody>
                </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FOURTH YEAR: (Clinical Clerkship)</Accordion.Header>
                <Accordion.Body>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th>Subjects</th>
                        <th scope="col" >Months</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Medicine</td>
                        <td>2.0 months</td>
                    </tr>
                    <tr>
                        <td>Surgery (General & Orthopedic)</td>
                        <td>2.0 months</td>
                    </tr>
                    <tr>
                        <td>Pediatrics </td>
                        <td>2.0 months</td>
                    </tr>
                    <tr>
                        <td>Obstetrics & Gynecology</td>
                        <td>2.0 months</td>
                    </tr>
                    <tr>
                        <td>Preventive, Family & Social Medicine</td>
                        <td>2.0 months</td>
                    </tr>
                    <tr>
                        <td>Psychiatry</td>
                        <td>0.5 months</td>
                    </tr>
                    <tr>
                        <td>Radiology</td>
                        <td>0.5 months</td>
                    </tr>
                    <tr>
                        <td>Pathology</td>
                        <td>0.5 months</td>
                    </tr>
                    <tr>
                        <td>Family Medicine</td>
                        <td>0.5 months</td>
                    </tr>
                    <tr>
                        <td>Total:</td>
                        <td>12.0 months</td>
                    </tr>
                </tbody>
                </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
      </div>
    </>
    
  )
}

export default Medicine
