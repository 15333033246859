import React, { useState, useEffect } from 'react';
import '../../../../assets/style/StructuralOrg.css';
import axios from 'axios';

function StructuralOrg() {
  const [orgChartUrl, setOrgChartUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchOrgChart() {
      try {
        const response = await axios.get('https://misadmin.drtrefi.edu.ph/api/orgchart?orgname=structural&display_site=rtr');
        const filename = response.data.results.image_filename;
        setOrgChartUrl(`https://misadmin.drtrefi.edu.ph/public/images/orgchart/${filename}`);
        console.log('Full API Response:', response.data.results);
      } catch (error) {
        console.error('Error fetching the organizational chart:', error);
        setError('Failed to load the organizational chart. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    fetchOrgChart();
  }, []);

  return (
    <div className="research-org-container">
      <header className="org-header">
        {/* <h1>Organizational Structure</h1> */}
      </header>
      {loading ? (
        <p>Loading organizational chart...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <img src={orgChartUrl} alt="Organizational Chart" className="org-chart-img" />
      )}
    </div>
  );
}

export default StructuralOrg;
