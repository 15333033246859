import React from "react";
import { Navbar, Container,Nav, NavDropdown} from "react-bootstrap";
import '../../assets/style/Main.css';
import HandBook from '../../assets/files/HANDBOOK.pdf';


const NavBar = () => {
    return (
        <Navbar collapseOnSelect expand="lg" className=" bg-body-tertiary fixed-top">
          <Container>
            <Navbar.Brand href="/">DRTREFI.EDU.PH</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="navbar-container me-auto">
                    <NavDropdown title="ABOUT" id="collapsible-nav-dropdown">
                        <NavDropdown title="GENERAL INFORMATION" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/about/mission-vision">ABOUT</NavDropdown.Item>
                            {/* <NavDropdown.Item href="/about/core-values">CORE VALUES </NavDropdown.Item> */}
                            <NavDropdown.Item href="/about/history">HISTORY </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="BOARD" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/board/structural-organization">STRUCTURAL ORGANIZATION</NavDropdown.Item>
                            {/* <NavDropdown.Item href="/board/administrators">ADMINISTRATOR</NavDropdown.Item> */}
                            <NavDropdown.Item href="#">ADMINISTRATOR</NavDropdown.Item>
                        </NavDropdown>
                    </NavDropdown>
                    {/* <Nav.Link href="/articles/News">NEWS</Nav.Link> */}
                    <NavDropdown title="ADMISSION" id="collapsible-nav-dropdown">
                        <NavDropdown title="UNDERGRADUATE PROGRAM" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/admission/bs-nursing">BACHELOR OF SCIENCE IN NURSING</NavDropdown.Item>
                            <NavDropdown.Item href="/admission/bs-physical-Therapy">BACHELOR OF SCIENCE IN PHYSICAL THERAPY</NavDropdown.Item>
                            <NavDropdown.Item href="/admission/bs-biology">BACHELOR OF SCIENCE IN BIOLOGY</NavDropdown.Item>
                            <NavDropdown.Item href="/admission/bs-medical-technology">BACHELOR OF SCIENCE IN MEDICAL TECHNOLOGY</NavDropdown.Item>
                        </NavDropdown>
                      <NavDropdown title="POST GRADUATE PROGRAM" id="collapsible-nav-dropdown-submenu" drop="end"> 
                          <NavDropdown.Item href="/admission/doctor-of-medicine">DOCTOR OF MEDICINE </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="GRADUATE PROGRAM" id="collapsible-nav-dropdown-submenu" drop="end"> 
                          <NavDropdown.Item href="/admission/ma-nursing">MASTER OF ARTS IN NURSING</NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="STUDENTS" id="collapsible-nav-dropdown" drop="end">
                          <NavDropdown.Item href={HandBook} download target="_blank" rel="noreferrer">DOWNLOAD STUDENT HANDBOOK</NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown.Item href="/admission/academic-calendar">ACADEMIC CALENDAR</NavDropdown.Item>
                      <NavDropdown.Item href="/admission/enrollment-flow">ENROLMENT FLOW</NavDropdown.Item>
                    </NavDropdown>
                    
                    <NavDropdown title="ACADEME" id="collapsible-nav-dropdown">
                        <NavDropdown title="UNDERGRADUATE PROGRAM" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/academe/bs-nursing">COLLEGE OF NURSING</NavDropdown.Item>
                            <NavDropdown.Item href="/academe/bs-medical-technology">COLLEGE OF MEDICAL TECHNOLOGY</NavDropdown.Item>
                            {/* <NavDropdown.Item href="/academe/bs-physical-therapy">COLLEGE OF PHYSICAL THERAPY</NavDropdown.Item> */}
                            {/* <NavDropdown.Item href="/academe/bs-biology">COLLEGE OF BIOLOGY</NavDropdown.Item> */}
                            <NavDropdown title="COLLEGE OF BIOMEDICAL SCIENCE" id="collapsible-nav-dropdown-submenu" drop="end"> 
                                <NavDropdown.Item href="/academe/bs-biology">DEPARTMENT OF BIOLOGY</NavDropdown.Item>
                                <NavDropdown.Item href="/academe/bs-physical-therapy">DEPARTMENT OF PHYSICAL THERAPY</NavDropdown.Item>
                            </NavDropdown> 
                        </NavDropdown>
                        <NavDropdown title="POST GRADUATE PROGRAM" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/academe/doctor-of-medicine">DOCTOR OF MEDICINE </NavDropdown.Item>
                            <NavDropdown.Item href="/academe/doctor-of-medicine-laboratory-hospital">(LABORATORY HOSPITAL)</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="GRADUATE PROGRAM" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/academe/master-of-arts-in-nursing">MASTER OF ARTS IN NURSING</NavDropdown.Item>
                        </NavDropdown>
                    </NavDropdown>
                  
                    <NavDropdown title="RESEARCH AND EXTENSION" id="collapsible-nav-dropdown">
                        <NavDropdown title="RESEARCH" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/research/mission-vision">ABOUT</NavDropdown.Item>
                            <NavDropdown title="ORGANIZATIONAL CHART" id="collapsible-nav-dropdown-submenu " drop="end"> 
                                <NavDropdown.Item href="#">RESEARCH OFFICE</NavDropdown.Item>
                                <NavDropdown.Item href="#">RESEARCH PUBLICATION OFFICE </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="E-JOURNAL" id="collapsible-nav-dropdown-submenu" drop="end"> 
                                <NavDropdown.Item href="#">ISSUES</NavDropdown.Item>
                                <NavDropdown.Item href="#">GUIDELINES FOR SUBMISSIONS</NavDropdown.Item>
                                <NavDropdown.Item href="#">Editorial Board</NavDropdown.Item>
                            </NavDropdown>
                            {/* <NavDropdown.Item href="/research/faculty-personnel-research">FACULTY & PERSONNEL RESEARCHES</NavDropdown.Item> */}
                            <NavDropdown.Item href="#">FACULTY & PERSONNEL RESEARCHES</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="EXTENSION" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/extension/about">ABOUT</NavDropdown.Item>
                            <NavDropdown.Item href="#">EXTENSION MANUAL</NavDropdown.Item>
                            <NavDropdown.Item href="#">EXTENSION ACTIVITIES</NavDropdown.Item>
                            <NavDropdown.Item href="#">ORGANIZATIONAL STRUCTURES</NavDropdown.Item>
                        </NavDropdown>
                    </NavDropdown>
                    {/* For comment */}
                    {/* <NavDropdown title="RESEARCH AND EXTENSION" id="collapsible-nav-dropdown">
                        <NavDropdown title="RESEARCH" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/research/mission-vision">ABOUT</NavDropdown.Item>
                            <NavDropdown title="ORGANIZATIONAL CHART" id="collapsible-nav-dropdown-submenu " drop="end"> 
                                <NavDropdown.Item href="/research/research-organizational-chart">RESEARCH OFFICE</NavDropdown.Item>
                                <NavDropdown.Item href="/research/research-publications">RESEARCH PUBLICATION OFFICE </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="E-JOURNAL" id="collapsible-nav-dropdown-submenu" drop="end"> 
                                <NavDropdown.Item href="/e-journal/journals">ISSUES</NavDropdown.Item>
                                <NavDropdown.Item href="/e-journal/guidelines">GUIDELINES FOR SUBMISSIONS</NavDropdown.Item>
                                <NavDropdown.Item href="/e-journal/editorial-board">Editorial Board</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown.Item href="/research/faculty-personnel-research">FACULTY & PERSONNEL RESEARCHES</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="EXTENSION" id="collapsible-nav-dropdown-submenu" drop="end"> 
                            <NavDropdown.Item href="/extension/about">ABOUT</NavDropdown.Item>
                            <NavDropdown.Item href="#">EXTENSION MANUAL</NavDropdown.Item>
                            <NavDropdown.Item href="/extension/extensionactivities">EXTENSION ACTIVITIES</NavDropdown.Item>
                            <NavDropdown.Item href="/extension/organizational-structures">ORGANIZATIONAL STRUCTURES</NavDropdown.Item>
                        </NavDropdown>
                    </NavDropdown> */}
                    <NavDropdown title="INTERNATIONALIZATION & LINKAGES" id="collapsible-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">ABOUT</NavDropdown.Item>
                        {/* <NavDropdown.Item href="#action/3.1">GOALS & OBJECTIVES</NavDropdown.Item> */}
                        <NavDropdown.Item href="#action/3.1">INTERNATIONALIZATION ACTIVITIES </NavDropdown.Item>
                        {/* <NavDropdown.Item href="/organizational-structures">ORGANIZATIONAL STRUCTURES</NavDropdown.Item> */}
                        <NavDropdown.Item href="#">ORGANIZATIONAL STRUCTURES</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="ALUMNI" id="collapsible-nav-dropdown">
                      <NavDropdown.Item href="#action/3.1">MISSION & VISION </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.1">GOALS & OBJECTIVES</NavDropdown.Item>
                  </NavDropdown>
                  
              </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    );
  };

  export default NavBar; 