import React from 'react';
import { Table, Accordion, Card} from 'react-bootstrap';
import '../../../assets/style/Academe.css';
import orgchart from '../../../assets/img/facultychart/medtech.png';
import drtref from '../../../assets/img/logo/medtech.png';


function BsMedTech() {
  return (
      <div className='container'>
        <div className='header-container'>
            <h1>COLLEGE OF MEDICAL LABORATORY SCIENCE</h1>
            <hr class="my-1"></hr>
        </div>
        <div className='content'>
          <div className='col-10'>
            <h3>VISION</h3>
            <p>The College envisioned itself as one of the prime movers in delivering quality Medical Technology education.</p>
            <br/>
            <h3>MISSION</h3>
            <p>Guided with the Institution’s core values, the College is committed to produce proficient, competent and globally competitive professionals in the field of Medical Laboratory Science and actively engaging in Research and Community Service.</p>
          </div>
          <div className='col-10'>
              <img className='imgnursing' src={drtref} alt=''/>
          </div>
        </div>
        <section className='values'>
          <h3 style={{fontWeight: 'bold', textAlign: 'center'}}>Core Values</h3>
          <Table className='mt-3' striped bordered hover>
            <tbody>
              <tr>
                <td>1. Competence</td>
                <td>2. Compassion</td>
                <td>3. Caring</td>
              </tr>
              <tr>
                <td>4. Commitment to Quality Service</td>
                <td>5. Responsive to Social Needs</td>
                <td>6. Value-driven</td>
              </tr>
            </tbody>
          </Table>
        </section>
        <div className="subhead mt-5 flex flex-col">
          <h4 style={{fontWeight: 'bold', textAlign: 'center'}}>PROGRAM OUTCOMES</h4>
          <Table class="table" size="sm" bordered>
            <thead>
              <tr>
                  <th className='col-5'>PROGRAM EDUCATION OBJECTIVES (PEO)</th>
                  <th className='col-5'>PROGRAM OUTCOMES (PO)/PLO/SLO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>The graduates of BMLS/BSMT have the ability to:</td>
                <td></td>
              </tr>
              <tr>
                <td>A. Demonstrate technical competence in the performance of clinical laboratory tests in aid of       diagnosis, treatment, and management of diseases vis-à-vis biosafety and waste management;</td>
                <td>A. Collect, handle and process biological specimens properly and safely, perform laboratory testing accurately through the use of appropriate techniques, skills and technology and analyze and interpret laboratory test data</td>
              </tr>
              <tr>
                <td>B. Demonstrate analytical and critical thinking skills in the workplace;</td>
                <td>B. Evaluate the validity of the generated data and assure its reliability before reporting and recognize errors/problems and perform root cause analysis to establish a course of action</td>
              </tr>
              <tr>
                <td>C. Engage in the collection, analysis, and projection of health information for improving the health care management system;</td>
                <td>C. Collect, analyze, interpret health information for health care management; contribute in designing and planning a course of action to address health concerns and issues.</td>
              </tr>
              <tr>
                <td>D. Demonstrate inter-personal skills, leadership qualities, and ethical practice of the profession; </td>
                <td>D. Work effectively with peers, and with multi-disciplinary and multi-cultural teams and demonstrate good inter-personal skills with patients. Exhibit ethical behavior and practice professionalism, principles of data security and patient’s confidentiality</td>
              </tr>
              <tr>
                <td>E. Apply research skills in relevant areas of Medical Technology/Medical Laboratory Science practice</td>
                <td>E. Identify research topic and perform research relevant to medical technology/medical laboratory science, analyze, interpret research data and disseminate research results.</td>
              </tr>
              <tr>
                <td>F. Participate in community-oriented activities;</td>
                <td>F. engage in community-oriented activities, implement, monitor, and evaluate activities in   the community.</td>
              </tr>
              <tr>
                <td>G. Engage in life-long learning activities.</td>
                <td>G. Keep abreast with developments in the Medical Technology/Medical Laboratory Science profession, participate in professional organizations and engage in continuing professional development activities.</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <Accordion className='accordion-container mt-5'>
          <Card>
            <Accordion.Header>View Organizational Chart</Accordion.Header>
            <Accordion.Body>
              <img src={orgchart} alt='Organizational Chart' className='img-fluid' />
            </Accordion.Body>
          </Card>
        </Accordion>
      </div>
  )
}

export default BsMedTech
