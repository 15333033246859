import React from 'react';
import drtref from '../../../assets/img/logo/pt.png';
import '../../../assets/style/Academe.css';
import { Table, Accordion, Card } from 'react-bootstrap';
import orgchart from '../../../assets/img/facultychart/pt.png';

function BsPT() {
  return (
      <div className='container'>
        <div className='header-container'>
            <h1>COLLEGE OF PHYSICAL THERAPY</h1>
            <hr class="my-1"></hr>
        </div>
        <h3>PHILOSOPHY</h3>
        <p>The program believes in the concept of ―adult learning, where instructors serve as facilitators of the learning and thinking process, and not solely as dispensers of knowledge. Hence, educational process centers on learning and thinking. Valuable educational experience is a responsibility of both the instructors and students by vibrantly exchanging ideas and continual outcome – based assessment. </p>
        <br/>
        <div className='content'>
          <div className='col-10'>
            <h3>VISION</h3>
            <p>The Department envisions of becoming a center of academic excellence and a fortress of global Physiotherapists.</p>
            <br/>
            <h3>MISSION</h3>
            <p>In accord to the Foundations existence, the Department undertakes to mold a highly competitive Physical Therapist who will assist in mainstreaming persons with physical disability. </p>
          </div>
          <div className='col-10'>
              <img id='imgnursing' src={drtref} alt=''/>
          </div>
        </div>
        <br/>
        <div className='values mt-5'>
          <h3><b>CORE VALUES AND OBJECTIVES</b></h3>
          <hr class="my-1"></hr>
          <h5 className='mt-4'>COMPETENT</h5>
          <p>To produce a physical therapist who consistently uses current knowledge and theory while understanding personal limits, integrates judgment and the patient/client perspective, embraces advancement, challenges mediocrity, and works toward development of new knowledge.</p>
          <h5>COMPASSIONATE</h5>
          <p>To produce a physical therapist who has a desire to identify with or sense something of another’s experience and a professional who has concern, empathy, and consideration for the needs and values of others.</p>
          <h5>COMMITTED</h5>
          <p>To produce a physical therapist who has active acceptance of the responsibility for the diverse roles, obligations, and actions of the physical therapist including self- regulation and other behaviors that positively influence patient/client outcomes, the profession and the health needs of society and a professional who is committed to provide effective physical therapy services to patients/clients, to serve the profession, and to positively influence the health of society.</p>
          <h5>RESILIENT</h5>
          <p>To produce a professional who is consistently involved in the promotion of a mutual trust between the profession and the larger public that necessitates responding to societal needs for health and wellness.</p>
          <h5>VALUE- DRIVEN</h5>
          <p>To produce a physical therapist who adheres to high ethical principles or professional standards; truthfulness, fairness, doing what you say you will do, and “speaking forth” about why you do what you do and a professional who has primary regard for or devotion to the interest of patients/clients, thus assuming the fiduciary responsibility of placing the needs of the patient/client ahead of his own interest.</p>
        </div>
        <div className="mt-5 flex flex-col">
          <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>PROGRAM OUTCOMES</h4>
          <Table class="table" size="sm" bordered>
            <thead>
              <tr>
                  <th className='col-5'>Program Outcomes</th>
                  <th className='col-5'>Graduate Attributes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1. Employ the latest developments in the specific field of practice;</td>
                <td>1. Globally competitive professional</td>
              </tr>
              <tr>
                <td>2. Internalize the passion for lifelong learning and culture for excellence;</td>
                <td>2. Passionate learner</td>
              </tr>
              <tr>
                <td>3. Demonstrate effective verbal and writing skills in both English and Filipino</td>
                <td>3. Articulate graduate</td>
              </tr>
              <tr>
                <td>4. Conduct research and promote its utilization;</td>
                <td>4. Research-oriented practitioner</td>
              </tr>
              <tr>
                <td>5. Engage in inter and multi-disciplinary collaboration with diverse members of the community</td>
                <td>5. Team player/effective collaborator</td>
              </tr>
              <tr>
                <td>6. Demonstrate leadership qualities and analytical and critical thinking skills;</td>
                <td>6. Leader and critical thinker</td>
              </tr>
              <tr>
                <td>7. Practice the specific field of discipline in accordance with professional, social and ethical standards; and</td>
                <td>7. Ethically conscious and socially responsive professional</td>
              </tr>
              <tr>
                <td>8. Preserve and promote Filipino values, heritage, culture and responsible citizenship.</td>
                <td>8. Nationalistic and value-oriented citizen </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <Accordion className='accordion-container mt-5'>
          <Card>
            <Accordion.Header>View Organizational Chart</Accordion.Header>
            <Accordion.Body>
              <img src={orgchart} alt='Organizational Chart' className='img-fluid' />
            </Accordion.Body>
          </Card>
        </Accordion>
      </div>
  )
}

export default BsPT
