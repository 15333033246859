import React from 'react';
import { Accordion, Table} from 'react-bootstrap';
import '../../../../assets/style/Admission.css';


function MsNursing() {
  return (
    <div className='admission-container'>
        <div className='ad-header'>
            <h1><b>MASTER OF ARTS IN NURSING</b></h1>
            <h3><b>(Major in Nursing Education)</b></h3>
            <hr class="my-1"></hr>
        </div>
        <div className='admission-req'>
            <h3><b>ADMISSION REQUIREMENTS</b></h3>
            <p>The following are the requirements for admission to the course:</p>
            <p>1. Bachelor’s degree in Nursing;</p>
            <p>2. Have passed the Nurse’s Licensure Examination;</p>
            <p>3. Have at least one (1) year experience as a nurse;</p>
            <p>4. To submit documents enumerated below for evaluation:</p>
            <p>a. Honorable Dismissal and/or Certified Xerox copy of Transcript of Records from last school attended;</p>
            <p>b. Certificate of Good Moral Character;</p>
            <p>c. Letters or Recommendation from two (2) persons of authority certifying ability of applicant to pursue graduate work and/or assume leadership role;</p>
            <p>d. Properly accomplished application form for graduate study; and </p>
            <p>5. Have passed the Entrance Examination.</p>
        </div>
        <div className="subhead-ad">
            <hr class="my-5"></hr>
            <h4><b>CURRICULUM</b></h4>
            <span>Per CMO No. 55 s. 2017</span>
        </div>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FIRST YEAR</Accordion.Header>
                <Accordion.Body>
                <h7>FIRST SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th scope="col" >Course</th>
                        <th scope="col" >Units</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Foundations in Nursing</td>
                        <td>3.00</td>
                    </tr>
                    <tr>
                        <td>Nursing Education I</td>
                        <td>3.00</td>
                    </tr>
                    <tr>
                        <td>Interpersonal Relations and Group Dynamics</td>
                        <td>3.00</td>
                    </tr>
                    <tr>
                        <td>Human Resource Development</td>
                        <td>3.00</td>
                    </tr>
                    <tr>
                        <td>Total No. of Units:</td>
                        <td>12.00</td>
                    </tr>
                </tbody>
                </Table>
                <h7>SECOND SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th scope="col">Course</th>
                        <th scope="col">Units</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Trends & Issues in Nursing</td>
                        <td>3.00</td>
                    </tr>
                    <tr>
                        <td>Nursing Education II</td>
                        <td>3.00</td>
                    </tr>
                    <tr>
                        <td>Research</td>
                        <td>3.00</td>
                    </tr>
                    <tr>
                        <td>Statistics</td>
                        <td>3.00</td>
                    </tr>
                    <tr>
                        <td>Total No. of Units:</td>
                        <td>12.00</td>
                    </tr>
                </tbody>
                </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SECOND YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                    <tr>
                        <th scope="col" >Course</th>
                        <th scope="col" colspan="3" className='units'>Units</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Curriculum Development and Supervision of Instruction</td>
                            <td>3.00</td>
                        </tr>
                        <tr>
                            <td>Nursing Education III</td>
                            <td>3.00</td>
                        </tr>
                        <tr>
                            <td>Nursing Education IV (Practicum)</td>
                            <td>3.00</td>
                        </tr>
                        <tr>
                            <td>Total No. of Units:</td>
                            <td>9.00</td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                    <tr>
                        <th scope="col" >Course</th>
                        <th scope="col" colspan="3" className='units'>Units</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Graduate Seminar</td>
                            <td>3.00</td>
                        </tr>
                        <tr>
                            <td>Thesis Writing</td>
                            <td>6.00</td>
                        </tr>
                        <tr>
                            <td>Total No. of Units:</td>
                            <td>9.00</td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
  )
}

export default MsNursing
