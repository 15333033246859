import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

// Importing components
import NavBar from './components/navbar/NavBar';
import Home from './components/landingpage/Home';
import Footer from './components/footer/Footer';

// Importing pages for About section
import About from './components/pages/about/About';
import History from './components/pages/about/History';
import CoreValues from './components/pages/about/CoreValues';
import StructuralOrg from './components/pages/about/board/StructuralOrg';
import Administrator from './components/pages/about/board/Administrator';

// Importing pages for Admission section
import PT from './components/pages/admission/undergrad/PT';
import Biology from './components/pages/admission/undergrad/Biology';
import Nursing from './components/pages/admission/undergrad/Nursing';
import MedTech from './components/pages/admission/undergrad/MedTech';
import Medicine from './components/pages/admission/postgrad/Medicine';
import MsNursing from './components/pages/admission/graduate/MsNursing';
import Calendar from './components/pages/admission/Calendar';
import EnrollmentFlow from './components/pages/admission/EnrollmentFlow';

// Importing pages for Academe section
import BsNursing from './components/pages/academe/BsNursing';
import BsPT from './components/pages/academe/BsPT';
import BsBiology from './components/pages/academe/BsBiology';
import BsMedTech from './components/pages/academe/BsMedTech';
import DoctorMed from './components/pages/academe/DoctorMed';
import MaNursing from './components/pages/academe/MaNursing';

// Importing pages for Research section
import ResearchAbout from './components/pages/res-ex/About';
import Research from './components/pages/res-ex/Journal';
import JournalContent from './components/pages/res-ex/JournalContent';

// Importing pages for E-Journal section
import Journals from './components/pages/res-ex/ejournal/MainPage';
import Guidelines from './components/pages/res-ex/ejournal/Guidelines';
import EditorialBoard from './components/pages/res-ex/ejournal/EditorialBoard';

// Importing articles section
import Article from './components/pages/articles/Article';
import News from './components/pages/articles/News';
import SinglePage from './components/pages/articles/SinglePage';

// Importing articles section
import ExtensionAbout from './components/pages/extension/About';
import ExtensionActivities from './components/pages/extension/ExtensionActivities';
import ActivityCard from './components/pages/extension/ActivityCard';
import ResearchOrg from './components/pages/extension/orgchart/ResearchOrg';
import ResearchPub from './components/pages/extension/orgchart/ResearchPub';
import LabHospital from './components/pages/academe/LabHospital';
import ExtensionChart from './components/pages/res-ex/ExtensionChart';

//Importing Internationalization & linkages
import OrganizationalStructure from './components/pages/linkages/Organizational-Structure';


function App() {
  return (
    <div className='outer-wrapper relative'>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />

          {/* About Routes */}
          <Route path='about'>
            <Route path='mission-vision' element={<About />} />
            {/* <Route path='core-values' element={<CoreValues />} /> */}
            <Route path='history' element={<History />} />
          </Route>

          {/* Board Routes */}
          <Route path='board'>
            <Route path="structural-organization" element={<StructuralOrg />} />
            <Route path="administrators" element={<Administrator />} />
          </Route>

          {/* Articles Routes */}
          <Route path='articles'>
            <Route path='article' element={<Article />} />
            <Route path='news' element={<News />} />
            <Route path='singlepage/:short_title' element={<SinglePage />} />
          </Route>

          {/* Admission Routes */}
          <Route path='admission'>
            <Route path='bs-nursing' element={<Nursing />} />
            <Route path='bs-physical-therapy' element={<PT />} />
            <Route path='bs-biology' element={<Biology />} />
            <Route path='bs-medical-technology' element={<MedTech />} />
            <Route path='doctor-of-medicine' element={<Medicine />} />
            <Route path='ma-nursing' element={<MsNursing />} />
            <Route path='academic-calendar' element={<Calendar />} />
            <Route path='enrollment-flow' element={<EnrollmentFlow />} />
          </Route>

          {/* Academe Routes */}
          <Route path='academe'>
            <Route path='bs-nursing' element={<BsNursing />} />
            <Route path='bs-physical-therapy' element={<BsPT />} />
            <Route path='bs-biology' element={<BsBiology />} />
            <Route path='bs-medical-technology' element={<BsMedTech />} />
            <Route path='doctor-of-medicine' element={<DoctorMed />} />
            <Route path='doctor-of-medicine-laboratory-hospital' element={<LabHospital />} />
            <Route path='master-of-arts-in-nursing' element={<MaNursing />} />
          </Route>

          {/* Research Routes */}
          <Route path='research'>
            <Route path='mission-vision' element={<ResearchAbout />} />
            <Route path='faculty-personnel-research' element={<Research />} />
            <Route path='faculty-personnel-research/:id' element={<JournalContent />} />
            <Route path='research-organizational-chart' element={<ResearchOrg />} />
            <Route path='research-publications' element={<ResearchPub />} />
          </Route>

          {/* E-Journal Routes */}
          <Route path='e-journal'>
            <Route path='journals' element={<Journals />} />
            <Route path='guidelines' element={<Guidelines />} />
            <Route path='editorial-board' element={<EditorialBoard />} />
          </Route>

          {/* Extension Routes */}
          <Route path='extension'>
            <Route path='about' element={<ExtensionAbout />} />
            <Route path='extensionactivities' element={<ExtensionActivities />} />
            <Route path="activitycard/:id" element={<ActivityCard />} />
            <Route path='organizational-structures' element={<ExtensionChart />} />
          </Route>

          {/* Internalization & linkages */}
          <Route path=''>
            <Route path='organizational-structures' element={<OrganizationalStructure />} />
          </Route>
          
          {/* Fallback Route */}
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

function NotFound() {
  return <div>404 - Page Not Found</div>;
}

export default App;
