import React from 'react';
import '../../../../assets/style/Admission.css';
import { Accordion, Table } from 'react-bootstrap';


function PT() {
  return (
    <div className='admission-container'>
        <div className='ad-header'>
            <h1><b>BACHELOR OF SCIENCE IN PHYSICAL THERAPY</b></h1>
            <hr class="my-1"></hr>
        </div>
        <div className='admission-req'>
            <h3><b>ADMISSION REQUIREMENTS</b></h3>
            <p>An applicant for enrolment to the course satisfy the following requirements:</p>
            <p>1. Be a senior high school graduate from any accredited school with a general weighted average rating of 80% or better.</p>
            <p>2. Have passed the entrance examination and personal interview</p>
            <p>3. With a good moral character</p>
            <p>4. Be physically fit, and</p>
            <p>5. Preferably, not more than 30 years old</p>
            <br/>
            <p>No applicant shall be admitted for Registration unless he/she presents the following credentials:</p>
            <p>1. Form 138 (SHS Report Card)</p>
            <p>2. PSA/Birth Certificate (Photocopy)</p>
            <p>3. Certificate of Good Moral Character from last school attended, and</p>
            <p>4. Medical Certificate issued by an DRTREFI – designated physician.</p>
        </div>
        <div className="subhead-ad">
            <hr class="my-5"></hr>
            <h4><b>CURRICULUM</b></h4>
            <span>Per CMO No. 55 s. 2017</span>
        </div>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FIRST YEAR</Accordion.Header>
                <Accordion.Body>
                <h7>FIRST SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th scope="col" style={{width:'15%'}}>Course Code</th>
                        <th scope="col" >Course</th>
                        <th scope="col" colspan="3" className='units'>Units</th>
                        <th scope="col">Contact Hours</th>
                        <th scope="col">Pre Requisites</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='thead'>
                        <th></th>
                        <td></td>
                        <td>Lec</td>
                        <td width={5}>Lab</td>
                        <td width={5}>Total</td>
                        <td></td>
                        <td></td>
                    </tr>
                        <tr>
                        <th>GE1123</th>
                        <td>Mathematics in the Modern World</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1133</th>
                        <td>Science, Technology, and Society</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1143</th>
                        <td>Purposive Communication</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PT1113</th>
                        <td>Zoology</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PT1123</th>
                        <td>Introduction to Physical Therapy</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>1</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PT1133</th>
                        <td>Gross and Organ Systems Anatomy</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>1</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PT1143</th>
                        <td>Physiology</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1152</th>
                        <td>Physical Activities Toward Health and Fitness 1 (PATHFit 1)</td>
                        <td style={{textAlign:'center'}}>(2)</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>(2)</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1163</th>
                        <td>NSTP</td>
                        <td style={{textAlign:'center'}}>(3)</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>(3)</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE Elective 1</th>
                        <td>Gender & Society</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className='subtotal'>
                        <td colSpan={3}>Sub-Total:</td>
                        <td>27</td>
                        <td>36</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                </Table>
                <h7>SECOND SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                    <th scope="col">Course Code</th>
                    <th scope="col">Course</th>
                    <th scope="col" colspan="3" className='units'>Units</th>
                    <th scope="col">Contact Hours</th>
                    <th scope="col">Pre Requisites</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='thead'>
                        <th></th>
                        <td></td>
                        <td>Lec</td>
                        <td width={5}>Lab</td>
                        <td width={5}>Total</td>
                        <td></td>
                        <td></td>
                    </tr>
                        <tr>
                        <th>GE1213</th>
                        <td>Readings in the Philippine History</td>
                        <td>3</td>
                        <td>0</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1223</th>
                        <td>Contemporary World</td>
                        <td>3</td>
                        <td>0</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1233</th>
                        <td>Art Appreciation</td>
                        <td>3</td>
                        <td>0</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1243</th>
                        <td>Rizal</td>
                        <td>3</td>
                        <td>0</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1253</th>
                        <td>Ethics</td>
                        <td>3</td>
                        <td>10</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PT1212</th>
                        <td>Applied Physics</td>
                        <td>1</td>
                        <td>1</td>
                        <td>2</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PT1223</th>
                        <td>Anatomy for Physical Therapy</td>
                        <td>2</td>
                        <td>1</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PT1233</th>
                        <td>Chemistry</td>
                        <td>(2)</td>
                        <td>0</td>
                        <td>(2)</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE1273</th>
                        <td>NSTP</td>
                        <td>(3)</td>
                        <td>0</td>
                        <td>(3)</td>
                        <td></td>
                        <td></td>
                    </tr>
                        <tr>
                        <th>GE Elective 2</th>
                        <td>Environmental Science</td>
                        <td>3</td>
                        <td>0</td>
                        <td>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className='subtotal'>
                        <td colSpan={3}>Sub-Total:</td>
                        <td>26</td>
                        <td>37</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SECOND YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                        <th scope="col">Course Code</th>
                        <th scope="col">Course</th>
                        <th scope="col" colspan="3" className='units'>Units</th>
                        <th scope="col">Contact Hours</th>
                        <th scope="col">Pre Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td>Lec</td>
                            <td width={5}>Lab</td>
                            <td width={5}>Total</td>
                            <td></td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>PT2115</th>
                            <td>Kinesiology and Biomechanics</td>
                            <td>3</td>
                            <td>2</td>
                            <td>5</td>
                            <td>9</td>
                            <td>PT1223, PT1212</td>
                        </tr>
                        <tr>
                            <th>PT2122</th>
                            <td>Psychiatric Foundations</td>
                            <td>2</td>
                            <td>0</td>
                            <td>2</td>
                            <td>2</td>
                            <td>PT1223</td>
                        </tr>
                        <tr>
                            <th>PT2134</th>
                            <td>Principles of PT Evaluation</td>
                            <td>2</td>
                            <td>2</td>
                            <td>4</td>
                            <td>8</td>
                            <td>PT1123, PT1223, PT1212</td>
                        </tr>
                        <tr>
                            <th>PT2142</th>
                            <td>Physical Agents and Electrotherapy</td>
                            <td>1</td>
                            <td>1</td>
                            <td>2</td>
                            <td>4</td>
                            <td>PT1123, PT1223, PT1212, PT1233</td>
                        </tr>
                        <tr>
                            <th>PT2152</th>
                            <td>Assistive Technologies</td>
                            <td>1</td>
                            <td>1</td>
                            <td>2</td>
                            <td>4</td>
                            <td>PT1123, PT1223, PT1212</td>
                        </tr>
                        <tr>
                            <th>PT2163</th>
                            <td>Neuroanatomy</td>
                            <td>3</td>
                            <td>0</td>
                            <td>3</td>
                            <td>3</td>
                            <td>PT1123, PT1143, PT1223, PT1233</td>
                        </tr>
                        <tr>
                            <th>PT2172</th>
                            <td>Exercise Science</td>
                            <td>1</td>
                            <td>1</td>
                            <td>2</td>
                            <td>4</td>
                            <td>PT1123, PT1223, PT1212</td>
                        </tr>
                        <tr>
                            <th>PT1233</th>
                            <td>Chemistry</td>
                            <td>(2)</td>
                            <td>0</td>
                            <td>(2)</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>PT2182</th>
                            <td>Human Development</td>
                            <td>2</td>
                            <td>0</td>
                            <td>2</td>
                            <td>2</td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>GE2112</th>
                            <td>Physical Activities Toward Health and Fitness 3 (PATHFit 3)</td>
                            <td>(2)</td>
                            <td>0</td>
                            <td>(2)</td>
                            <td>(2)</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={3}>Sub-Total:</td>
                            <td>22</td>
                            <td>38</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <th scope="col">Contact Hours</th>
                            <th scope="col">Pre Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td>Lec</td>
                            <td width={5}>Lab</td>
                            <td width={5}>Total</td>
                            <td></td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>PT2213</th>
                            <td>Orthopedic/Rheuma/Sports Physical Therapy1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>5</td>
                            <td>PT2115, PT2134, PT2142, PT2152, PT2172</td>
                        </tr>
                        <tr>
                            <th>PT2223</th>
                            <td>Neurologic Physical Therapy 1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td >PT2115, PT2134,PT2142,PT2152,PT2163,PT2172</td>
                        </tr>
                        <tr>
                            <th>PT2243</th>
                            <td>Introduction to Research</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>must be a regular second year, second semester student</td>
                        </tr>
                        <tr>
                            <th>PT2253</th>
                            <td>Cardio Pulmo PT</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>PT2115, PT2134, PT2142, PT2152, PT2172</td>
                        </tr>
                        <tr>
                            <th>PT2263</th>
                            <td>Teaching and Learning in Physical Therapy</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>PT2273</th>
                            <td>Organization and Administration</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>PT2282</th>
                            <td>Pharmacology in Rehabilitation</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>PT1123, PT1143, PT1223, PT1233, PT2163</td>
                        </tr>
                        <tr>
                            <th>GE2212</th>
                            <td>Physical Activities Toward Health and Fitness 4 (PATHFit 4)</td>
                            <td style={{textAlign:'center'}}>(2)</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>(2)</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={3}>Sub-Total:</td>
                            <td>23</td>
                            <td>37</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>THIRD YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <th scope="col">Contact Hours</th>
                            <th scope="col">Pre Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td>Lec</td>
                            <td width={5}>Lab</td>
                            <td width={5}>Total</td>
                            <td></td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>PT3112</th>
                            <td>CBR 1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>2</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3123</th>
                            <td>Orthopedic/Rheuma/Sports Physical Therapy 2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3133</th>
                            <td>Neurologic PT 2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3141</th>
                            <td>Industrial Rehabilitation</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3155</th>
                            <td>Multisystem PT Management</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3162</th>
                            <td>Research Proposal Writing</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3175</th>
                            <td>Health Education and Promotion</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3183</th>
                            <td>Health Informatics</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>GE Elective 3</th>
                            <td>Living in the IT Era</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={3}>Sub-Total:</td>
                            <td>27</td>
                            <td>39</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <th scope="col">Contact Hours</th>
                            <th scope="col">Pre Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td>Lec</td>
                            <td width={5}>Lab</td>
                            <td width={5}>Total</td>
                            <td></td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>PT3213</th>
                            <td>CBR 2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3222</th>
                            <td>Seminar</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3233</th>
                            <td>Professional Ethics</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3243</th>
                            <td>Research Implementation</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>7</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3252</th>
                            <td>Screening & Differential Diagnosis</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3265</th>
                            <td>Emerging Practice in Physical Therapy</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr>
                            <th>PT3273</th>
                            <td>Orthopedic/Rheumatologic/Sports Physical Therapy 3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>A regular third year student</td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={3}>Sub-Total:</td>
                            <td>21</td>
                            <td>39</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FOURTH YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <th scope="col">Contact Hours</th>
                            <th scope="col">Pre Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td>Lec</td>
                            <td width={5}>Lab</td>
                            <td width={5}>Total</td>
                            <td></td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>PT4113</th>
                            <td>Special Topics in Physical Therapy 1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>A regular fourth year student</td>
                        </tr>
                        <tr>
                            <th>PT4123</th>
                            <td>Research Dissemination and Translation</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>A regular fourth year student</td>
                        </tr>
                        <tr>
                            <th>PT400A</th>
                            <td>Clinical Internship 1</td>
                            <td style={{textAlign:'center'}}></td>
                            <td style={{textAlign:'center'}}>15</td>
                            <td style={{textAlign:'center'}}>15</td>
                            <td style={{textAlign:'center'}}></td>
                            <td>A regular fourth year student</td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={3}>Sub-Total:</td>
                            <td>19</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <th scope="col">Contact Hours</th>
                            <th scope="col">Pre Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td>Lec</td>
                            <td width={5}>Lab</td>
                            <td width={5}>Total</td>
                            <td></td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>PT4213</th>
                            <td>Special Topics in Physical Therapy</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>A regular fourth year student</td>
                        </tr>
                        <tr>
                            <th>PT4221</th>
                            <td>Writing for Publication</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td>A regular fourth year student</td>
                        </tr>
                        <tr>
                            <th>PT400B</th>
                            <td>Clinical Internship 2</td>
                            <td style={{textAlign:'center'}}></td>
                            <td style={{textAlign:'center'}}>15</td>
                            <td style={{textAlign:'center'}}>15</td>
                            <td style={{textAlign:'center'}}></td>
                            <td>A regular fourth year student</td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={3}>Sub-Total:</td>
                            <td>21</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
  )
}

export default PT
