import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../assets/style/Articles.css';

// Helper function to strip HTML tags from a string
const stripHtmlTags = (html) => {
  if (!html) return '';
  return html.replace(/<\/?[^>]+>/gi, '');
};

// Helper function to limit title to 5 words
const limitTitleWords = (title, wordLimit = 5) => {
  const words = title.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return title;
};

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const NewsUpdate = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      const apiUrl = `https://misadmin.drtrefi.edu.ph/api/articles?display_site=rtr&limit=8`;
      try {
        const response = await axios.get(apiUrl);
        const articles = response.data.results;
        const combinedArticles = articles.map(article => ({
          ...article,
          body: stripHtmlTags(article.body)
        }));
        setArticles(combinedArticles);  // Update state with combined articles
      } catch (error) {
        console.error(`Failed to fetch articles`, error);
      }
    };
    fetchArticles();
  }, []);

  return (
    <>
      <section className="hero">
        <div className="container">
          {Array.isArray(articles) && articles.length > 0 ? (
            articles.map(({ short_title: id, title, author, body, cover_image, created_at }, index) => (
              <div className="box" key={id}>
                <div className="img">
                  <Image 
                    src={cover_image}
                    alt={title} 
                  />
                </div>
                <div className="text">
                  <Link to={`/articles/singlepage/${id}`}>
                    <h1 className="titleBg">{limitTitleWords(title.toUpperCase())}</h1>
                    <h6 className="created-at">Publish Date: {formatDate(created_at)}</h6>
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <p>No articles available.</p>
          )}
        </div>
      </section>
      <div className="loadMore">
        <Button variant="outline-success" href="/articles/News">Load More Updates</Button>
      </div>
    </>
  );
};

export default NewsUpdate;
