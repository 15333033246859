import React from 'react';
import '../../../assets/style/AboutDetails.css';
import historyImage from '../../../assets/img/rtrbldg.jpg'; 

function History() {
  return (
    <div className="history-container">
      <div className="history-about">
        <div className="history-header">
          <h1 style={{fontWeight: 'bold',textAlign: 'center'}}>DOÑA REMEDIOS TRINIDAD ROMUALDEZ EDUCATIONAL FOUNDATION INC.(Formerly RTRMF)</h1>
        </div>
        <h1 className='hist-drtr' style={{fontWeight: 'bold',textAlign: 'center'}}>HISTORY</h1>
        {/* Image Section */}
        <div className="image-container">
          <img 
            src={historyImage} 
            alt="History of DRTREFI" 
            className="w-full max-w-3xl mx-auto rounded-lg shadow-lg"
          />
        </div>

        {/* History Details */}
        <div className="history-details text-justify leading-relaxed px-4 md:px-8 lg:px-16">
          <p className="mb-4">
            With the aim to solve the shortage of doctors in Eastern Visayas and to make medical education accessible to students in the region, an innovative health manpower development program was established by Hon. Benjamin T. Romualdez, the founding father of RTR Medical Foundation and Chairman Emeritus of the RTRMF Board of Trustees. With the support of the governing board of the Makati Medical Center, a medical school was opened in the school year 1980-1981, with the Makati Medical Center as its teaching hospital.
          </p>
          <p className="mb-4">
            Dr. Roque D. Yutangco, President of the RTRMF Board of Trustees, was appointed Dean of the College of Medicine in the school year 1980-1981. Under his leadership for two decades, the RTRMF earned a prominent name in the academe.
          </p>
          <p className="mb-4">
            The first RTRMF building, initially situated at T. Claudio Street (Kanhuraw Hill), became the site for medical education, independently offering the first and second years of the medical curriculum in the school year 1981-1982.
          </p>
          <p className="mb-4">
            In 1980, an agreement with the Ministry of Health provided the sharing of physical and manpower resources and affiliation with Daniel Z. Romualdez Hospital, the Tacloban City Health Office, and other RHUs as clinical training grounds for its students. A tripartite agreement with the Ministry of Health and the University of the Philippines Institute of Health Science was made in 1982.
          </p>
          <p className="mb-4">
            The first batch of 55 doctors graduated in 1984, achieving a high passing rate in the 1985 Medical Board Examination, placing the school 6th among the 30 medical schools nationwide. The same year, the Daniel Z. Romualdez School of Nursing was absorbed as the College of Nursing. The institution later affiliated with Bethany Hospital in 1985 and expanded its affiliations to include various other hospitals across the region.
          </p>
          <p className="mb-4">
            With the help of the late Dr. Julio E. Dolorico, a member of the RTRMF Board of Trustees, the RTRMF three-building complex at the Bethany Hospital compound was completed in the school year 1987-1988, serving as its main campus. In 1994, the first three-story building at its permanent campus site in Calanipawan, Tacloban City, was inaugurated and blessed.
          </p>
          <p className="mb-4">
            The foundation is the first to offer a graduate program in Nursing, with the pioneering class of 19 in the school year 1999-2000. In June 1994, the DVOREF for the College of Law was opened, followed by the opening of the SHS in June 2017 and the College of Criminology and College of Entrepreneurship in June 2020.
          </p>
        </div>
      </div>
    </div>
  );
}

export default History;