import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../../../assets/style/NewsUpdates.css';

// Helper function to strip HTML tags from the article body and limit to 10 words
const stripHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const text = tempDiv.textContent || tempDiv.innerText || '';
    return text.split(' ').slice(0, 10).join(' ') + (text.split(' ').length > 10 ? '...' : '');
};

const NewsArticleList = () => {
    const [articles, setArticles] = useState([]);
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const articlesPerPage = 12;

    const fetchArticles = async () => {
        if (isLoading) return;

        setIsLoading(true);
        const apiUrl = `https://misadmin.drtrefi.edu.ph/api/articles?display_site=rtr&limit=${articlesPerPage}&offset=${offset}`;
        try {
            const response = await axios.get(apiUrl);
            const newArticles = response.data.results.map(article => ({
                ...article,
                body: stripHtmlTags(article.body),
            }));

            console.log("Fetched Articles:", newArticles);

            setArticles(prevArticles => {
                const existingIds = new Set(prevArticles.map(article => article.id));
                const filteredNewArticles = newArticles.filter(article => !existingIds.has(article.id));
                return [...prevArticles, ...filteredNewArticles];
            });

        } catch (error) {
            console.error('Failed to fetch articles', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        console.log("Fetching articles with offset:", offset);
        fetchArticles();
    }, [offset]);

    const handleLoadMore = () => {
        setOffset(prevOffset => prevOffset + articlesPerPage);
    };

    return (
        <Container>
            <h1 className="my-4">News Articles</h1>
            <Row className="row-news">
                {articles.length === 0 ? (
                    <Col>
                        <p>No news articles available.</p>
                    </Col>
                ) : (
                    articles.map(article => (
                        <Col md={4} key={article.id} className="mb-4">
                            <Card>
                                <Card.Img variant="top" src={article.cover_image} />
                                <Card.Body>
                                    <Card.Title>
                                        {article.title.split(' ').slice(0, 5).join(' ')}
                                        {article.title.split(' ').length > 5 && '...'}
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{article.body}</Card.Subtitle>
                                    <Card.Text>{article.snippet}</Card.Text>
                                    <a href={`/articles/singlepage/${article.short_title}`} className="read-more">Read more</a>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                )}
            </Row>
            <div className="loadMore text-center my-4">
                <Button variant="outline-success" onClick={handleLoadMore} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'LOAD MORE'}
                </Button>
            </div>
        </Container>
    );
};

export default NewsArticleList;