import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../assets/style/AcademicCalendar.css';

function Calendar() {
    const [calendar, setCalendar] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchCalendar() {
            try {
                const response = await axios.get('https://misadmin.drtrefi.edu.ph/api/calendars');
                if (response.data && response.data.calendars) {
                    setCalendar(response.data.calendars);
                    console.log('Full API Response:', response.data);
                    console.log('Calendar:', response.data.calendars);
                    setError(null);
                } else {
                    setError('Failed to fetch calendar. Please try again later.');
                    setCalendar({});
                }
            } catch (error) {
                console.error('API Error:', error);
                setError('Failed to fetch calendar. Please try again later.');
                setCalendar({});
            }
        }

        fetchCalendar();
    }, []);

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className='main-container'>
            <div className='calendar-header'>
                <h3>SCHOOL CALENDAR</h3>
                <hr className="my-1"></hr>
            </div>
            <div className="departments mt-4">
                {Object.keys(calendar).length > 0 ? (
                    Object.keys(calendar).map((sy, index) => (
                        <div key={index} className="school-year-section">
                            <h4>SCHOOL YEAR: {sy}</h4>
                            <div className="department-list">
                                {calendar[sy].map(({ id, department, file_url }) => (
                                    <div key={id} className="department-item">
                                        <span className="department-name">{department}</span>
                                        <a href={file_url} target="_blank" rel="noreferrer" download className="btn btn-link">
                                            Download
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (   
                    <p></p>
                )}
            </div>
        </div>
    );
}

export default Calendar;