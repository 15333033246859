import React from 'react';
import '../../../../assets/style/Admission.css';
import { Accordion, Table } from 'react-bootstrap';

function Biology() {
  return (
    <div className='admission-container'>
        <div className='ad-header'>
            <h1><b>BACHELOR OF SCIENCE IN BIOLOGY (Medical Biology)</b></h1>
            <hr class="my-1"></hr>
        </div>
        <div className='admission-req'>
            <h3><b>ADMISSION REQUIREMENTS</b></h3>
            <p>An applicant for admission to the course must satisfy the following requirements:</p>
            <p>1. Must be a high school graduate from any accredited school with a general weighted average of 80% or better.</p>
            <p>2. Must have passed the entrance examination and personal interview</p>
            <p>3. Must be of good moral character</p>
            <p>4. Physically fit and preferably not more than 30 years of age.</p>
            <p>5. Shifty from other courses within the institution is accepted provided he/she has a satisfactory scholastic performance from the previous College attended.</p>
            <p>6. Transferee from other HEI’s are also accepted provided he/she has a good scholastic performance from previous school last attended and passed the Entrance examination and interview.</p>
            <br/>
            <p>No applicant shall be admitted for registration unless he/she has submitted the following    credentials:</p>
            <p>1. Form 138 (High School Report Card) or its equivalent</p>
            <p>2. Birth certificate (NSO certified True Copy)</p>
            <p>3. Certificate of good moral character from the last school attended</p>
            <p>4. Medical Certificate issued by RTR-MF designated physician</p>
            <p>5. For shifty, Certified true copy of grades from the Registrar’s office</p>
            <p>6. For transferee’s, TOR and Honorable Dismissal from the previous School</p>
        </div>
        <div className="subhead-ad">
            <hr class="my-5"></hr>
            <h4><b>CURRICULUM</b></h4>
            <span>CHED MEMO 49, SERIES 2017</span>
        </div>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FIRST YEAR</Accordion.Header>
                <Accordion.Body>
                <h7>FIRST SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course Title</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <td>Grades</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td width={5}>Lecture</td>
                            <td width={5}>Laboratory</td>
                            <td width={5}>Total</td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>Bio 101</th>
                            <td>General Botany</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 102</th>
                            <td>General Zoology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 101</th>
                            <td>Understanding Self</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 102</th>
                            <td>Readings in Phil. History</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 103</th>
                            <td>Mathematics in the Modern World</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 109-1</th>
                            <td>Environmental Science</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 110-1</th>
                            <td>PE 1 Personal Fitness and Wellness</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 111-1</th>
                            <td>NSTP 1: National Services & Training Programs</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td style={{textAlign:'center'}}>23</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>27</td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                <h7>SECOND SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course Title</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <td>Grades</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td width={5}>Lecture</td>
                            <td width={5}>Laboratory</td>
                            <td width={5}>Total</td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>Bio 103</th>
                            <td>Systematics</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 111</th>
                            <td>Chemical Biology I (Organic Molecules)</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>BIO 115</th>
                            <td>Biostatistics (Statistical Biology)</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 104</th>
                            <td>Purposive Communication</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 105</th>
                            <td>Art Appreciation</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 109-2</th>
                            <td>Art and Humanities</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 110-2</th>
                            <td>PE 2 Dance Arts</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 111-1</th>
                            <td>NSTP 2: National Services & Training Programs</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td style={{textAlign:'center'}}>21</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>25</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SECOND YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course Title</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <td>Grades</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td width={5}>Lecture</td>
                            <td width={5}>Laboratory</td>
                            <td width={5}>Total</td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>Bio 104</th>
                            <td>Microbiology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 105</th>
                            <td>General Ecology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>BIO 112</th>
                            <td>Chemical Biology II (Analytical Methods for Biology)</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 106</th>
                            <td>Science, Technology and Society</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 107</th>
                            <td>Ethics</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 110-3</th>
                            <td>PE 3 Tournament Management and Team Sports</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 112</th>
                            <td>Rizal</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td style={{textAlign:'center'}}>19</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>24</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>THIRD YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course Title</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <td>Grades</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td width={5}>Lecture</td>
                            <td width={5}>Laboratory</td>
                            <td width={5}>Total</td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>Bio 108</th>
                            <td>General Physiology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 109</th>
                            <td>Cell and Molecular Biology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 110</th>
                            <td>Developmental Biology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td style={{textAlign:'center'}}>6</td>
                            <td style={{textAlign:'center'}}>15</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course Title</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <td>Grades</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td width={5}>Lecture</td>
                            <td width={5}>Laboratory</td>
                            <td width={5}>Total</td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>Bio 114</th>
                            <td>Biophysics</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 205</th>
                            <td>Medical Histology</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 207</th>
                            <td>Medical Microbiology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 301</th>
                            <td>Bioethics</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 303</th>
                            <td>Thesis 1: Basic Research and Research Proposal</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td style={{textAlign:'center'}}>13</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>18</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FOURTH YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course Title</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <td>Grades</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td width={5}>Lecture</td>
                            <td width={5}>Laboratory</td>
                            <td width={5}>Total</td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>Bio 202</th>
                            <td>Human Anatomy and Physiology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 203</th>
                            <td>Epidomology</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 204</th>
                            <td>Immunology</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 302</th>
                            <td>Principles & strategies in Teaching Health Education</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td style={{textAlign:'center'}}>10</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>14</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course Title</th>
                            <th scope="col" colspan="3" className='units'>Units</th>
                            <td>Grades</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td width={5}>Lecture</td>
                            <td width={5}>Laboratory</td>
                            <td width={5}>Total</td>
                            <td></td>
                        </tr>
                            <tr>
                            <th>Bio 201</th>
                            <td>Medical Parasitology</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 206</th>
                            <td>Human Genetics/ Cytogenetics</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 304</th>
                            <td>Thesis 2: Research Seminar & Defense</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Bio 305</th>
                            <td>Practicum (150 hours)</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td style={{textAlign:'center'}}>10</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>12</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
  )
}

export default Biology
