import React from 'react';
import '../../assets/style/Footer.css';

function Footer() {
  return (
    <footer className="footer mt-5 text-white">
      <div className="footer-container mx-auto py-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Google Maps Embed */}
          <div className="map">
            <iframe
              title="location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.6652308701027!2d124.99731037418321!3d11.212385988964108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3308771c90084afd%3A0xc6f0e867d037ebd1!2sR%20T%20R%20Medical%20Foundation%20College!5e0!3m2!1sen!2sph!4v1708770884742!5m2!1sen!2sph"
              className="w-full h-65"
            ></iframe>
          </div>

          {/* Contact Information */}
          <div className='contact'>
            <h4 className="text-xl font-semibold mb-4">Contact Information</h4>
            <div className="space-y-4">
              <p className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-6 w-6 text-green-400"
                >
                  <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                  <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                </svg>
                Barangay 96 Calanipawan Road, Tacloban City, Philippines 6500
              </p>
              <p className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-6 w-6 text-green-400"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                rtradmin@drtrefi.edu.ph
              </p>
              <p className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="mr-3 h-6 w-6 text-green-400"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                    clipRule="evenodd"
                  />
                </svg>
                +63 968 690 8255
              </p>
            </div>
          </div>
          {/* Official Email Addresses */}
          <div>
            <h5 className="text-xl font-semibold mb-4">Official Email Addresses</h5>
            <ul className="space-y-2">
              <li>Registrar Office: registrar@drtrefi.edu.ph</li>
              <li>Human Resource: hr@drtrefi.edu.ph</li>
              <li>Guidance Office: guidance@drtrefi.edu.ph</li>
              <li>Student Affairs: studentaffairs@drtrefi.edu.ph</li>
            </ul>
          </div>
        </div>
        <hr className="my-6 border-white-500 mx-auto w-3/4" />
        <div className="flex flex-wrap items-center justify-center">
        <div className="w-full text-center">
            <p className="text-sm font-semibold text-center">© 2024 Copyright DRTREFI. All Rights Reserved.</p>
        </div>
        </div>

      </div>
    </footer>
  );
}

export default Footer;