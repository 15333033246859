import React from 'react';
import { Table, Accordion } from 'react-bootstrap';
import drtref from '../../../assets/img/logo/nursing.png';
import orgchart from '../../../assets/img/facultychart/nursing.png'; 
import '../../../assets/style/Academe.css';

const BsNursing = () => {

  return (
    <div className="container">
      <header className="header-container">
        <h1><b>COLLEGE OF NURSING</b></h1>
        <hr className="my-1" />
      </header>

      <section className="content">
        <div className="content-text">
          <h3><b>VISION</b></h3>
          <p>
            In accordance with the mission and vision of the Doña Remedios Trinidad Romualdez Educational Foundation, Inc.,
            the DRTRIFI-College of Nursing envisions becoming a Center of Excellence in Nursing Education in Region VIII.
          </p>

          <h3><b>MISSION</b></h3>
          <p>
            The DRTREFI-College of Nursing aims to produce globally competitive and socially responsive nurses who are
            value-driven, committed, compassionate, and creative. The college specifically aims to provide quality
            transformational nursing education.
          </p>
        </div>

        <div className="content-image">
          <img id="imgnursing" src={drtref} alt="College of Nursing Logo" />
        </div>
      </section>

      <section className="values mt-5">
        <h3><b>Core Values</b></h3>
        <Table className="mt-3" striped bordered hover>
          <tbody>
            <tr>
              <td>1. Competence</td>
              <td>2. Compassion</td>
              <td>3. Caring</td>
            </tr>

            <tr>
              <td>4. Commitment to Quality Service</td>
              <td>5. Responsive to Social Needs</td>
              <td>6. Value-driven</td>
            </tr>
          </tbody>
        </Table>
      </section>

      <section className="program-outcomes mt-5">
        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Program Outcomes</h4>
        <Table className="table" size="sm" bordered>
          <thead>
            <tr>
              <th className="col-6">Program Outcomes</th>
              <th className="col-6">Graduate Attributes</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                Apply knowledge of physical, social, natural, and health sciences and humanities in the practice of nursing 
                across the lifespan.
              </td>
              <td>Holistic and Compassionate Practitioner</td>
            </tr>

            <tr>
              <td>
                Provide safe, quality, appropriate, culturally sensitive, and holistic care to individuals, families, 
                population groups, and communities utilizing the nursing process.
              </td>
              <td>Safe, Quality, and Systematic Health Provider</td>
            </tr>

            <tr>
              <td>
                Apply guidelines and principles of evidence-based practice in the delivery of care in any setting.
              </td>
              <td>Evidence-based Practitioner</td>
            </tr>

            <tr>
              <td>
                Practice nursing in accordance with professional standards, legal, and ethical-moral principles.
              </td>
              <td>Ethically, Morally, and Legally Conscious Professional</td>
            </tr>

            <tr>
              <td>
                Communicate effectively with other disciplines and multi-cultural teams, using intra- and interpersonal 
                collaborative skills.
              </td>
              <td>Effective Communicator</td>
            </tr>

            <tr>
              <td>
                Document and report on client care accurately and comprehensively utilizing appropriate and effective 
                recording methods.
              </td>
              <td>Effective Recorder</td>
            </tr>

            <tr>
              <td>
                Work effectively with other disciplines and multi-cultural teams, using intra- and interpersonal collaborative skills.
              </td>
              <td>Team Player</td>
            </tr>

            <tr>
              <td>
                Practice beginning management, leadership, and decision-making skills in the delivery of quality care.
              </td>
              <td>Leader and Decisive Manager</td>
            </tr>

            <tr>
              <td>
                Conduct research with an experienced researcher; present/publish results of scientific investigations in academic 
                fora and utilize relevant research findings.
              </td>
              <td>Researcher</td>
            </tr>

            <tr>
              <td>
                Engage in lifelong learning with a passion to keep current with national and global developments in general, and 
                nursing and health developments in particular.
              </td>
              <td>Committed Lifelong Learner</td>
            </tr>

            <tr>
              <td>
                Demonstrate responsible citizenship and pride in being a Filipino by preserving and promoting the nation's 
                heritage and culture by imbibing Filipino Values.
              </td>
              <td>Patriotic and Socially Responsive Filipino Nurse</td>
            </tr>

            <tr>
              <td>
                Design and apply techno-intelligent care systems and processes in health care delivery.
              </td>
              <td>Innovative and Technologically Competent</td>
            </tr>

            <tr>
              <td>
                Adopt the nursing core values in the delivery of nursing care.
              </td>
              <td>Value-centered</td>
            </tr>

            <tr>
              <td>
                Develop entrepreneurial skills in the delivery of nursing care.
              </td>
              <td>Nurse Entrepreneur</td>
            </tr>
          </tbody>
        </Table>

        <Accordion className="accordion-container mt-5">
          <Accordion.Item eventKey="0">
            <Accordion.Header>View Organizational Chart</Accordion.Header>
            <Accordion.Body>
              <img src={orgchart} alt="Organizational Chart" className="img-fluid" />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </div>
  );
}; 
export default BsNursing;
