import React from 'react';
import '../../../../assets/style/Admission.css';
import { Accordion, Table } from 'react-bootstrap';

function Nursing() {
  return (
      <div className='admission-container'>
        <div className='ad-header'>
            <h1><b>BACHELOR OF SCIENCE IN NURSING</b></h1>
            <hr class="my-1"></hr>
        </div>
        <div className='admission-req'>
            <h3><b>ADMISSION REQUIREMENTS</b></h3>
            <p>1. Every student enjoys the right to enroll at the Doña Remedios Trinidad Romualdez Medical Foundation Inc. – College of Nursing (DRTRMFI-CN) upon meeting specific requirements and regulations.
</p>
            <p>2. High School General Weighted Average of 82% or better (Form 137A).</p>
            <p>3. Three (3) Good Moral Character Certification from High School Principal/ Guidance Counselor, Parish Priest/Pastor, Barangay Chairman.</p>
            <p>4. Must be mentally, emotionally, psychosocially fit (no known history or must not have been diagnosed of any form of psychiatric disorder).</p>
            <p>5. No gross physical and speech deformity (gross deformity which may impair her performance in the care of her assigned patient/s).</p>
            <p>6. At least five (5) feet in height.</p>
            <p>7. Accomplished application form which includes the student- applicant’s personal data, parent’s guardian’s consent, promissory statement of applicant regarding compliance with all policies and standards of the RTRMF College of Nursing.</p>
            <p>8. Passed the Entrance Examination.</p>
            <p>9. Submit for an interview conducted by the assigned Nursing Faculty.</p>
            <p>10. For transferees, shifters, and second coursers, Transcript of Records and Honorable Dismissal will also be required in addition to the aforementioned requirements.</p>
            <p>11. For married applicants, authenticated proof of marriage either Civil Marriage Certificate and/or Church Marriage Certificate.</p>
            <p>12. All applicants are ranked based on their High School weighted average (35%); entrance exam (35%); interview results (30%). </p>
            <p>13. Medical and Laboratory exams (to include UCG for females) are required for those who have qualified for admission to the College, findings of which may affect the applicant’s acceptance to the course.</p>
        </div>
        <hr class="my-5"></hr>
        <div className="subhead-ad">
            <h3><b>CURRICULUM</b></h3>
            <span>Per CHED MEMO No. 15, s. 2017</span>
        </div>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FIRST YEAR</Accordion.Header>
                <Accordion.Body>
                <h7>FIRST SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th scope="col" style={{width:'15%'}}>Course Code</th>
                        <th scope="col" >Course</th>
                        <th scope="col" width={5}>Lec</th>
                        <th scope="col" width={5}>Lab</th>
                        <th scope="col" colspan="2" className='units'>RLE</th>
                        <th scope="col">Units</th>
                        <th scope="col">Pre-Requisites</th>
                        <th scope="col">Co-Requisites</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='thead'>
                        <th></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td width={2}>SL</td>
                        <td width={2}>C</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GEC 1</th>
                        <td>Understanding Self</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GEC 2</th>
                        <td>Readings  in Phil History (Phil History, Governance & Const. integrated)</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>MC 1</th>
                        <td>Anatomy and Physiology</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>5</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>MC 2</th>
                        <td>Biochemistry</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>5</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GEC 3</th>
                        <td>Mathematics in the Modern World (Biostatistics integrated)</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>NCM 100</th>
                        <td>Theoretical Foundations in Nursing</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PATHFIT 1 
                            <p>(Physical  Activity Towards Health & Fitness)</p>
                        </th>
                        <td>Movement Competency Training/Enhancement</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GEC 4</th>
                        <td>Art Appreciation</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th></th>
                        <td className='subtotal'>Total</td>
                        <td style={{textAlign:'center'}}>26</td>
                        <td style={{textAlign:'center'}}>4</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>27</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                </Table>
                <h7>SECOND SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th scope="col" style={{width:'15%'}}>Course Code</th>
                        <th scope="col" >Course</th>
                        <th scope="col" width={5}>Lec</th>
                        <th scope="col" width={5}>Lab</th>
                        <th scope="col" colspan="2" className='units'>RLE</th>
                        <th scope="col">Units</th>
                        <th scope="col">Pre-Requisites</th>
                        <th scope="col">Co-Requisites</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='thead'>
                        <th></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td width={2}>SL</td>
                        <td width={2}>C</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GEC 5</th>
                        <td>Purposive Communication (Technical Writing Integrated)</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>NCM 101</th>
                        <td>Health Assessment</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>5</td>
                        <td>MC 1-  Ana, MC2- Biochem, NCM100-TFN</td>
                        <td>MC 3  Micro</td>
                    </tr>
                    <tr>
                        <th>NCM 102</th>
                        <td>Health Education</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td>MC 1- Ana, NCM 100</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>NCM 103</th>
                        <td>Fundamentals of Nursing Practice</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}></td>
                        <td style={{textAlign:'center'}}>5</td>
                        <td>MC 1- Ana, MC 2-Biochem, NCM 100- TFN</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>MC 3</th>
                        <td>Microbiology & Parasitology</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>1</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>4</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PATHFIT 2 
                            <p>(Physical  Activity Towards Health & Fitness)</p>
                        </th>
                        <td>Movement Competency Training/Enhancement</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td>PATHFit  1</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GEC 6</th>
                        <td>Ethics</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th></th>
                        <td className='subtotal'>Total</td>
                        <td style={{textAlign:'center'}}>23</td>
                        <td style={{textAlign:'center'}}>1</td>
                        <td style={{textAlign:'center'}}>4</td>
                        <td style={{textAlign:'center'}}></td>
                        <td style={{textAlign:'center'}}>25</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SUMMER</Accordion.Header>
                <Accordion.Body>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>MC 4</th>
                            <td>Logic and Critical Thinking</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GEC 8</th>
                            <td>Environmental Science</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>6</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SECOND YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 104</th>
                            <td>Community Health Nursing I (Individual & Family as Clients)</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>NCM 100, NCM 101, NCM 103</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 105</th>
                            <td>Nutrition & Diet Therapy</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>MC 1-Ana, NCM 101, NCM 102, NCM 103, Biochem</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 106</th>
                            <td>Pharmacology</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>NCM 101, NCM 102, NCM 103, GEC 3- Math</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 107</th>
                            <td>Care of Mother, Child Adolescent (Well Client)</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td>NCM 100, NCM 101, NCM 102, NCM 103, MC 1- Ana</td>
                            <td>NCM105, NCM106</td>
                        </tr>
                        <tr>
                            <th>NCM 108</th>
                            <td>Health Care Ethics (Bioethics)</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>Ethics, NCM 103</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>PATHFIT 3 
                                <p>(Physical  Activity Towards Health & Fitness)</p>
                            </th>
                            <td>Dance Art</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>PATHFit  1 & 2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NSTP 1</th>
                            <td>NSTP 1 (Foundation of  Service  1)</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>Ethics, NCM 103</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>19</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>28</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 109</th>
                            <td>are of Mother, Child at Risk or  with Problems (Acute and Chronic)</td>
                            <td style={{textAlign:'center'}}>6</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>12</td>
                            <td>NCM 107</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 110</th>
                            <td>Nursing Informatics</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GEC 7</th>
                            <td>Science, Technology & Society</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NSTP 2</th>
                            <td>NSTP 2 (Social Awareness & Empowerment for Service)</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>Ethics, NCM 103</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>PATHFIT 3 
                                <p>(Physical  Activity Towards Health & Fitness)</p>
                            </th>
                            <td>Sports</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>PATHFit  1 & 2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>19</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>23</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SUMMER</Accordion.Header>
                <Accordion.Body>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 122</th>
                            <td>Decent Work Employment and Transcultural  Nursing</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GEC 9</th>
                            <td>The Entrepreneurial  Mind</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GEC 10</th>
                            <td>Spiritual Nursing Care</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>THIRD YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 111</th>
                            <td>Nursing Research 1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>GEC 3- Math</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 112</th>
                            <td>Care of Clients with Problems in Oxygenation, Fluid, & Electrolytes, Infectious Inflammatory and Immunologic  Response, Cellular Aberrations Acute & Chronic)</td>
                            <td style={{textAlign:'center'}}>8</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>14</td>
                            <td>NCM 109</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 113</th>
                            <td>Community Health Nursing 2</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>NCM 104</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 114</th>
                            <td>Care of Older Person</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>NCM 103</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>15</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>7</td>
                            <td style={{textAlign:'center'}}>24</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 11</th>
                            <td>Nursing Research 2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>NCM 111</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 116</th>
                            <td>Care of Clients  with Problems in Nutrition & Gastro-intestinal, Metabolism & Endocrine, 
        Perception and Coordination  (Acute & Chronic)</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td>NCM 112</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 117</th>
                            <td>Care of Clients with Maladaptive Patterns of Behavior, Acute and Chronic</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>8</td>
                            <td>NCM 108, NCM 112</td>
                            <td>NCM 116</td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>6</td>
                            <td style={{textAlign:'center'}}>19</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SUMMER</Accordion.Header>
                <Accordion.Body>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NSE 1</th>
                            <td>Nursing  Skills Enhancement 1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>NCM 107,  NCM 109, NCM 112, NCM 116, NCM 117</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FOURTH YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 118</th>
                            <td>Nursing Care of Clients with Life Threatening Conditions, Acutely III  / Multi-organ Problems, High Acuity and Emergency Situation, Acute and Chronic</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td>NCM 116, NCM 117</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 118</th>
                            <td>Nursing Leadership and Management</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>7</td>
                            <td>NCM 116, NCM 117</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Rizal 1</th>
                            <td>Life, Works and Writings of Rizal</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>NCM 116, NCM 117</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCA 1</th>
                            <td>Nursing  Course  Appraisal 1</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>NCM 103, NCM 104, NCM 106, NCM 107, NCM 109, NCM 112, NCM 113, NCM 116, NCM 117</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>15</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>7</td>
                            <td style={{textAlign:'center'}}>23</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col" style={{width:'15%'}}>Course Code</th>
                            <th scope="col" >Course</th>
                            <th scope="col" width={5}>Lec</th>
                            <th scope="col" width={5}>Lab</th>
                            <th scope="col" colspan="2" className='units'>RLE</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                            <th scope="col">Co-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='thead'>
                            <th></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td width={2}>SL</td>
                            <td width={2}>C</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 120</th>
                            <td>Disaster Nursing</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>NCM 103</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCM 121</th>
                            <td>Intensive Nursing Practicum (Community & Hospital)</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>8</td>
                            <td style={{textAlign:'center'}}>8</td>
                            <td>All Professional subjects</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GEC 11</th>
                            <td>The Contemporary World</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>NCA 2</th>
                            <td>Nursing Course Appraisal 2</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>NCA 1</td>
                            <td>NCA 1</td>
                        </tr>
                        <tr>
                            <th></th>
                            <td className='subtotal'>Total</td>
                            <td style={{textAlign:'center'}}>9</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>8</td>
                            <td style={{textAlign:'center'}}>18</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        {/* <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SUMMARY</Accordion.Header>
                <Accordion.Body>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <td scope="col" colspan="2" className='units'>SUMMARY</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>I. GENERAL EDUCATION  COURSES
                                <p>A. Core Courses</p>
                                <p>B. Mandated Courses</p>
                            </th>
                            <td>50 Units
                                <p>33 Units</p>
                                <p>17 Units</p>
                            </td>
                        </tr>
                        <tr>
                            <th>II. MAJOR COURSES</th>
                            <td>17 Units</td>
                        </tr>
                        <tr>
                            <th>III.  PROFESSIONAL COURSES</th>
                            <td>138 Units</td>
                        </tr>
                        <tr>
                            <th className='subtotal'>Grand Total No. of units</th>
                            <td>205 Units</td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion> */}
        <th></th>
        <br/>
        <Table class="table" size="sm" bordered>
            <thead>
                <tr>
                    <th scope="col" colspan="2" className='units'>SUMMARY</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>I. GENERAL EDUCATION  COURSES
                        <p>A. Core Courses</p>
                         <p>B. Mandated Courses</p>
                    </th>
                    <td>50 Units
                        <p>33 Units</p>
                        <p>17 Units</p>
                    </td>
                </tr>
                <tr>
                    <th>II. MAJOR COURSES</th>
                    <td>17 Units</td>
                </tr>
                <tr>
                    <th>III.  PROFESSIONAL COURSES</th>
                    <td>138 Units</td>
                </tr>
                <tr>
                    <th className='subtotal'>Grand Total No. of units</th>
                    <td>205 Units</td>
                </tr>
            </tbody>
        </Table>
      </div>
  )
}

export default Nursing
