import React from 'react';
import '../../../assets/style/Research.css';
import agendajpg from '../../../assets/img/picture.png';

function About() {
  return (
    <div className="about-container">
      <header>
      <h1 style={{fontWeight: 'bold',textAlign: 'center'}}>THE DRTREFI RESEARCH OFFICE</h1>
      </header>
      <section className="about-section">
        <h2>Vision</h2>
        <p>
          A forefront in providing a supportive environment to research enthusiasts of DRTR-EF and
          other institutions in the region, producing quality research in the Philippines recognized
          by the Global Community.
        </p>
      </section>

      <section className="about-section">
        <h2>Mission</h2>
        <p>
          The DRTR-EF Research Office is committed to instill the culture of research across the
          institution. Advance world-class research through capacity and capability building, and
          secure new resources for sustainability. The research center is also committed to
          contributing new knowledge, discoveries, and innovations that will benefit the Institution,
          the region, and the country in general.
        </p>
      </section>

      <section className="about-section">
        <h2>Objectives</h2>
        <ul>
          <li>Encourage faculty and non-teaching personnel to engage in research activities while enhancing and strengthening their research capabilities.</li>
          <li>Improve research quality and productivity, provide appropriate recognition to outstanding researchers, and publish research outputs in reputable publishers.</li>
          <li>Establish linkages and networks among educational institutions, private and public agencies, industry, and the community through collaboration and sharing of resources for research sustainability.</li>
          <li>Conduct quality research recognized by local and international communities, with relevant and responsive research that caters to the changing needs of society.</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>Research Agenda</h2>
        <article>
          <p><strong>Public Health, and Health Promotions</strong></p>
          <p>
            Focuses on research on responsive health systems which include service delivery, health
            human resources, health governance, economics, financing and regulation, health research
            management, and access to essential medical products, vaccines, and technology, including
            universal health care, information and communication technology for health, and the
            effectiveness of information, education, and communication materials in health promotion.
            This also involves community and public health concerns, as well as traditional and
            alternative medicine.
          </p>
        </article>

        <figure>
          <img src={agendajpg} alt="Research Agenda Framework" />
          <figcaption>Research Agenda Framework</figcaption>
        </figure>

        <article>
          <p><strong>Non-communicable Diseases, and Occupational Health</strong></p>
          <p>
            Focuses on researches on detection, prevention and control, risk assessment of leading non-communicable diseases such as cardiovascular diseases, chronic renal diseases, cancer, and diabetes, evaluation of intervention modalities. This also includes preventable injuries and accidents, toxicology, poisoning, animal bites, substance abuse. Likewise, researches on occupational safety and standards, occupational health hazards, impact of urbanization, industrialization, and globalization among workforce and other related researches.
          </p>
        </article>

        <article>
          <p><strong>Emerging and Re-emerging Infectious Diseases</strong></p>
          <p>
            Focuses on researches on the different pathogens with greater impact on the people and have the capacity to spread through outbreaks and pandemics. This includes but is not limited to Tuberculosis, diarrheal diseases, HIV, Dengue, Rabies, SARS-CoV-2, and other relevant communicable diseases. Focuses also on neglected Tropical Diseases, soil-transmitted helminthiasis, multi-drug-resistant pathogens, and evaluation of the effectiveness of the interventions.
          </p>
        </article>

        <article>
          <p><strong>Geriatric, Palliative Care, and Rehabilitation Medicine</strong></p>
          <p>
            Focuses on longevity and quality of life for elders, rehabilitative and palliative care, health and wellness, rehabilitation and technology.
          </p>
        </article>

        <article>
          <p><strong>Nutrition, Adolescent Health, Reproductive Health, and Maternal and Child Care</strong></p>
          <p>
            Focuses on studies regarding nutritional problems such as obesity and malnutrition, population control and reproductive health, newborn and child health, maternal health, maternal and infant mortality, teenage pregnancy, sexually transmitted diseases, mental health, and other related studies.
          </p>
        </article>

        <article>
          <p><strong>Health Technology, Discovery, and Innovation</strong></p>
          <p>
            Focuses on basic and advanced researches on the development of diagnostic tools and biomedical products and devices, drug discovery from natural sources, functional food development, Information and communication technologies for health, “OMIC” technologies (genomics, proteomics, transcriptomics, and metabolomics), and other innovations.
          </p>
        </article>

        <article>
          <p><strong>Nature, Environment, and Conservation</strong></p>
          <p>
            Focuses on environment and biodiversity, natural and physical sciences, climate change and its effect, waste management, energy and resources conservation, pollution control and prevention, ecological economics and technology, risk reduction and response management, geographical studies, environmental threats to health, and other related researches.
          </p>
        </article>

        <article>
          <p><strong>Society, Culture, and Human Behavior</strong></p>
          <p>
            Focuses on studies related to society in general, Filipino culture, social and behavioral sciences, gender-responsive researches, Indigenous people and marginalized people of society. This also includes but is not limited to studies on sociology and psychology, human rights and Law, violence against women and children, gender identities and sexual orientation, current and contemporary events, philosophy and theology, literature, inter-cultural studies, and other related studies.
          </p>
        </article>

        <article>
          <p><strong>Quality and Standards in Education and Globalization</strong></p>
          <p>
            Focuses on the development of education such as pedagogy, policies, curriculum, and teacher’s education. This includes technology and education, E-learning and distance learning and other teaching-learning modalities, indigenous education, trends in education, class-room based researches, and globalization in education.
          </p>
        </article>

        <article>
          <p><strong>Business and Product Development</strong></p>
          <p>
            Focuses on office and business management, breakthroughs and innovations in the business and corporate world, processes of strategic and organizational planning, from product conceptualization, marketing or advertising, evaluation, commercialization, and feedback. It also includes studies concerning the creation of new products, software, and innovations that offer additional benefits to the stakeholders.
          </p>
        </article>
      </section>
    </div>
  );
}

export default About;
