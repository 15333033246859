import React, { useEffect } from 'react';
import drtref from '../../../assets/img/drtref.png';
import '../../../assets/style/AboutDetails.css';

const Section = React.memo(({ title, children }) => (
  <div className='about-section scroll-reveal'>
    <h3>{title}</h3>
    {children}
  </div>
));

const About = () => {
  useEffect(() => {
    const revealElements = document.querySelectorAll('.scroll-reveal');

    const revealOnScroll = () => {
      revealElements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        if (elementTop < viewportHeight - 50) {
          element.classList.add('revealed');
        }
      });
    };

    // Initial check on load
    revealOnScroll();

    // Add scroll event listener
    window.addEventListener('scroll', revealOnScroll);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('scroll', revealOnScroll);
  }, []);

  return (
    <div className='about-container'>
      <div className='about-header'>
        <h1 style={{fontWeight: 'bold',textAlign: 'left',marginBottom: '2rem'}}>DOÑA REMEDIOS TRINIDAD ROMUALDEZ EDUCATIONAL FOUNDATION INC.</h1>
      </div>
      
      <div className='about-content'>
        <div className='row'>
          <div className='col-lg-6'>
            <h2 style={{fontWeight: 'bold'}}>VISION</h2>
              <p style={{textAlign: 'justify',marginBottom: '2rem'}}>The center for academic excellence and the bastion for integrated formation of globally competitive professionals.</p>
            <h2 style={{fontWeight: 'bold'}}>MISSION</h2>
              <p style={{textAlign: 'justify',marginBottom: '2rem'}}>To develop competent, value-driven and globally competitive professionals and leaders who are responsive to the changing needs of the society by providing quality transformational education.</p>
          </div>
          <div className='col-lg-6 d-flex justify-content-center align-items-center'>
            <img src={drtref} alt="DRTREF Logo" className='img-fluid about-image' />
          </div>
        </div>
        <div className='row' style={{marginTop: '3rem'}}>
          <div className='col-lg-6'>
            <h2 style={{fontWeight: 'bold'}}>OBJECTIVES</h2>
            <Section title="">
              <ul>
                <li>Employ the latest development in the specific field of practice.</li>
                <li>Internalize the passion for life-long learning and culture for excellence.</li>
                <li>Demonstrate effective communication skills orally and in writing using both English and Filipino.</li>
                <li>Conduct research & promote its utilization.</li>
                <li>Engage in inter-, intra-professional, and multicultural collaboration.</li>
                <li>Demonstrate leadership qualities & analytical & critical thinking skills.</li>
                <li>Practice the specific field of discipline in accordance with professional, social & ethical standards.</li>
                <li>Preserve and promote Filipino values, heritage, culture, responsible citizenship, and dynamic leadership.</li>
              </ul>
            </Section>
          </div>
          <div className='col-lg-6'>
          <h2 style={{fontWeight: 'bold'}}>GRADUATE ATTRIBUTES</h2>
            <Section title="">
              <ul>
                <li>Globally competitive professionals.</li>
                <li>Passionate learner.</li>
                <li>Articulate.</li>
                <li>Research-oriented practitioner.</li>
                <li>Team player/effective collaborator.</li>
                <li>Leader & critical thinker.</li>
                <li>Ethically conscious & socially responsive professional.</li>
                <li>Nationalistic & value-centered.</li>
              </ul>
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;