import React from 'react';
import orgchart from '../../../assets/img/enrollment.png';
import '../../../assets/style/AcademicCalendar.css';

function EnrollmentFlow() {
  return (
    <div className='container'>
        <div className="subhead mt-3 flex flex-col">
          <div className="mt-3">
            <img src={orgchart} alt="Organizational Chart" className="org-chart-img" />
          </div>
        </div>
        
    </div>
  )
}

export default EnrollmentFlow
