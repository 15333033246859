import React from 'react';
import drtref from '../../../assets/img/logo/med.png';
import '../../../assets/style/Academe.css';
import { Table, Accordion, Card } from 'react-bootstrap';
import orgchart from '../../../assets/img/facultychart/med.png'; // Import your additional image

function DoctorMed() {
  return (
    <div className='container'>
        <div className='header-container'>
            <h1>COLLEGE OF MEDICINE</h1>
            <hr class="my-1"></hr>
        </div>
        <br/>
        <div className='content'>
          <div className='col-10'>
            <h3>VISION</h3>
            <p>A leader in the development of globally competitive human resource for health in basic medical education.</p>
            <br/>
            <h3>MISSION</h3>
            <p>To develop committed, value-driven, and world-class medical Practitioners who will proactively address the need for sustained quality health outcomes.</p>
          </div>
          <div className='col-10'>
              <img id='imgnursing' src={drtref} alt=''/>
          </div>
        </div>
        <br/>
        <div>
        </div>
        <section className='values mt-5'>
          <h3 style={{fontWeight: 'bold', textAlign: 'center'}}>Core Values</h3>
          <Table className='mt-3' striped bordered hover>
            <tbody>
              <tr>
                <td>1. Competence</td>
                <td>2. Compassion</td>
                <td>3. Caring</td>
              </tr>
              <tr>
                <td>4. Commitment to Quality Service</td>
                <td>5. Responsive to Social Needs</td>
                <td>6. Value-driven</td>
              </tr>
            </tbody>
          </Table>
        </section>
        <div>
          <h4 style={{fontWeight: 'bold', marginTop: '5rem'}}>GOALS AND OBJECTIVES</h4>
          <p>The DRTREFI College of Medicine shall___________.</p>
          <p>1. Provide a viable learning environment for the progressive aiecinataas of program outcomes.</p>
          <p>2. Enhance the passion for life-long learning and culture of excellence.</p>
          <p>3. Promote the conduct of scientific investigation and utilization of research results towards evidence-based practice.</p>
          <p>4. Maximize the use of hands-on learning experiences and ensure adherence to professional, ethical, and legal standards of practice.</p>
          <p>6. Employ community-based leadership and management training across physical, social, and organizational spheres of health protection and maintenance.</p>
          <p>7. Extend its human and material resources for community-based programs especially towards the attainment of sustainable development goals (SGDs) of the country.</p>
          <p>8. Maximize the engagement of the medical alumni as developmental partners at local, national, and international levels.</p>
          </div>
        <div>
          <br/>
          <h4>GENERIC ABILITIES</h4>
          <p>The DRTREFI-CM Graduate is a</p>
          <p>Competent medical care Provider, Communicator, and Collaborator;</p>
          <p>Self-directed life-long Learner;</p>
          <p>Compassionate and Ethical Health Practitioner;</p>
          <p>Resilient Health Worker-Manager; and</p>
          <p>Value-driven Social Mobilizer.</p>
          </div>
          <Accordion className='accordion-container mt-5'>
            <Card>
              <Accordion.Header>View Organizational Chart</Accordion.Header>
              <Accordion.Body>
                <img src={orgchart} alt='Organizational Chart' className='img-fluid' />
              </Accordion.Body>
            </Card>
        </Accordion>
      </div>
  )
}

export default DoctorMed
