import React, { useEffect } from 'react';
import MainCarousel from './MainCarousel';
import NewsUpdate from './NewsUpdate';
import drtref from '../../assets/img/drtref.png';
import corevalue1 from '../../assets/img/corevalues/corevalue1.png';
import corevalue2 from '../../assets/img/corevalues/corevalue2.jpg';
import corevalue3 from '../../assets/img/corevalues/corevalue3.png';
import corevalue4 from '../../assets/img/corevalues/corevalue4.png';
import corevalue5 from '../../assets/img/corevalues/corevalue5.png';
import '../../assets/style/Main.css';


function Home() {
  useEffect(() => {
    const revealElements = document.querySelectorAll('.scroll-reveal');

    const handleScroll = () => {
      revealElements.forEach((element) => {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (elementTop < windowHeight - 100) {
          element.classList.add('scroll-reveal-active');
        } else {
          element.classList.remove('scroll-reveal-active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <MainCarousel />
      <section className='latest-update scroll-reveal'>
        <div className='header-update text-center'>
          <h2 style={{fontWeight: 'bold'}}>Latest Update</h2>
        </div>
        <div className='update'>
          <NewsUpdate />
        </div>
      </section>

      <section className='about-next m-4 scroll-reveal' id='about'>
        <div className='about m-4' id='about'>
          <div className='container'>
            <div className='col-10 m-5'>
              <img className='home-about' src={drtref} alt='' />
            </div>
            <div className='col-10 m-5 text-justify'>
              <h2>About DRTREFI</h2>
              <p>
              With the aim to solve the shortage of doctors in Eastern Visayas and to make medical education accessible to students in the region, an innovative health manpower development program was established by Hon. Benjamin T. Romualdez, the founding father of RTR Medical Foundation and Chairman Emeritus of the RTRMF Board of Trustees. With the support of the governing board of the Makati Medical Center, a medical school was opened in the school year 1980-1981, with the Makati Medical Center as its teaching hospital.
              </p>
              <a href="/about/history" className="read-more">Read more</a>
            </div>
          </div>
        </div>
      </section>

      <section className='core-values-section gtco-section scroll-reveal'>
        <div className='container'>
          <div className='text-center'>
            <h2>Our Core Values</h2>
          </div>
          <div className='row justify-content-center'>
            {coreValues.map((coreValue) => (
              <div
                key={coreValue.id}
                className='col-lg-4 mb-4'
              >
                <div className='core-value-box text-center p-4'>
                  <img
                    src={coreValue.imageUrl}
                    className='img-fluid mb-3'
                    alt={coreValue.title}
                  />
                  <h4>{coreValue.title}</h4>
                  <p>{coreValue.description}</p>
                </div>
                
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

const coreValues = [
  {
    id: 1,
    title: 'COMPETENT',
    imageUrl: corevalue1,
    // description: `To produce a physical therapist who consistently uses current knowledge and theory while understanding personal limits, integrates judgment and the patient/client perspective, embraces advancement, challenges mediocrity, and works toward development of new knowledge.`,
  },
  {
    id: 2,
    title: 'COMPASSIONATE',
    imageUrl: corevalue2,
    // description: `To produce a physical therapist who consistently uses current knowledge and theory while understanding personal limits, integrates judgment and the patient/client perspective, embraces advancement, challenges mediocrity, and works toward development of new knowledge.`,
  },
  {
    id: 3,
    title: 'COMMITTED',
    imageUrl: corevalue3,
    // description: `To produce a physical therapist who consistently uses current knowledge and theory while understanding personal limits, integrates judgment and the patient/client perspective, embraces advancement, challenges mediocrity, and works toward development of new knowledge.`,
  },
  {
    id: 4,
    title: 'RESILIENT',
    imageUrl: corevalue4,
    // description: `To produce a physical therapist who consistently uses current knowledge and theory while understanding personal limits, integrates judgment and the patient/client perspective, embraces advancement, challenges mediocrity, and works toward development of new knowledge.`,
  },
  {
    id: 5,
    title: 'VALUE DRIVEN',
    imageUrl: corevalue5,
    // description: `To produce a physical therapist who consistently uses current knowledge and theory while understanding personal limits, integrates judgment and the patient/client perspective, embraces advancement, challenges mediocrity, and works toward development of new knowledge.`,
  },
];

export default Home;
