import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../../assets/style/OrgChart.css';

function Administrator() {

  const [adminchart, setAdminChart] = useState('');
  
  useEffect(() => {
    async function fetchAdminChart() {
      try {
        const response = await axios.get('https://misadmin.drtrefi.edu.ph/api/orgchart?orgname=board-admin&display_site=rtr');
        const filename = response.data.results.image_filename;
        setAdminChart(`https://misadmin.drtrefi.edu.ph/public/images/orgchart/${filename}`);
        console.log('Full API Response:', response.data.results);
      } catch (error) {
        console.error('Error fetching the organizational chart:', error);
      }
    }
  
    fetchAdminChart(); // Call the function inside useEffect
  }, []);

  return (
    <div className="research-org-container">
      <header className="org-header">
        <h1>Adminstrators</h1>
      </header>
      {adminchart ? (
        <img src={adminchart} alt="Organizational Chart" className="org-chart-img" />
      ) : (
        <p>Loading organizational chart...</p>
      )}
    </div>
  )
}

export default Administrator
