import React from 'react';
import { Table, Accordion, Card} from 'react-bootstrap';
import '../../../assets/style/Academe.css';
import orgchart from '../../../assets/img/facultychart/bio.png';
import drtref from '../../../assets/img/logo/bio.png';

function BsBiology() {
  return (
    <div className='container'>
        <div className='header-container'>
            <h1><b>COLLEGE OF BIOLOGY (Medical Biology)</b></h1>
            <hr class="my-1"></hr>
        </div>
        <h3>OVERVIEW</h3>
        <p>The Bachelor of Science in Biology (Medical Biology) offered by Doña Remedios Trinidad Romualdez Medical Foundation is the first and only Medical Biology track in Region VIII. This program is intended to provide students an in-depth understanding of the biological principles underlying human health and medical sciences. The BS Medical Biology encompasses important biological concepts with focus on human anatomy and physiology, histology, medical microbiology, parasitology, and immunology.</p>
        <p>Graduates of the BS Medical Biology program have excellent laboratory and research skills which are relevant to those who are interested to pursue a career in the medical, research, or paramedical field. The program aims to produce professionals with a solid understanding of the biological systems particularly in the context of human health, fostering critical and analytical skills, and preparing students for the dynamic and evolving field of medical sciences.</p>
        <div className='content'>
          <div className='col-10'>
            <h4>VISION</h4>
            <p>The College envisions of becoming the center of academic excellence in the field of Biology in the Region.</p>
            <br/>
            <h4>MISSION</h4>
            <p>Primal to the Foundation’s organization and existence in the upliftment of the common man in the Eastern Visayas Region through the provision of quality health manpower, the college is committed to produce Professional Biologists who are competent in various areas of biology.</p>
          </div>
          <div className='col-10'>
              <img style={{width: '100%', height: 'auto'}} id='imgnursing' src={drtref} alt=''/>
          </div>
        </div>
        <section className='values mt-5'>
          <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Core Values</h3>
          <Table className='mt-3' striped bordered hover>
            <tbody>
              <tr>
                <td>1. Competence</td>
                <td>2. Compassion</td>
                <td>3. Caring</td>
              </tr>
              <tr>
                <td>4. Commitment to Quality Service</td>
                <td>5. Responsive to Social Needs</td>
                <td>6. Value-driven</td>
              </tr>
            </tbody>
          </Table>
        </section>
        <div className="subhead mt-5 flex flex-col">
          <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>PROGRAM OUTCOMES</h4>
          <Table class="table" size="sm" bordered>
            <thead>
              <tr>
                  <th className='col-5'>Program Outcomes</th>
                  <th className='col-5'>Graduate Attributes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1. Have an in-depth understanding of the basic principles governing biological science;</td>
                <td>1. Competent, theoretically-well equipped biologists;</td>
              </tr>
              <tr>
                <td>2. Be able to extend knowledge and understanding to a critical assessment of current views and theories in selected areas of the biological sciences;</td>
                <td>2. Committed to pursue continuing education for professional growth and to become globally competitive</td>
              </tr>
              <tr>
                <td>3. Be able to perform basic biological and chemical techniques in either laboratory or fieldwork research setting;</td>
                <td>3. Compassionate and caring biologists with strong sense of ethical behavior;</td>
              </tr>
              <tr>
                <td>4. Be able to carry out mathematical and statistical computations;</td>
                <td>4. Conduct relevant basic and applied researches in various areas of biology and engage in research related activities</td>
              </tr>
              <tr>
                <td>5. Have adequate technical writing skills and effective oral communications abilities;</td>
                <td></td>
              </tr>
              <tr>
                <td>6. Develop critical, analytical and problem-solving skills;</td>
                <td></td>
              </tr>
              <tr>
                <td>7. Develop the skills required for both autonomous practice and teamwork;</td>
                <td></td>
              </tr>
              <tr>
                <td>8. Be able to think critically active problems and appreciate the limitations and implications of science in everyday life;.</td>
                <td></td>
              </tr>
              <tr>
                <td>9. Develop an objective viewpoint for discerning information and analyzing biases of other people; </td>
                <td></td>
              </tr>
              <tr>
                <td>10. Be precise in making observations and able to distinguish small difference between samples and events; and mostly important</td>
                <td></td>
              </tr>
              <tr>
                <td>11. Have a strong sense of ethical behavior. </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>
        <Accordion className='accordion-container mt-5'>
          <Card>
            <Accordion.Header>View Organizational Chart</Accordion.Header>
            <Accordion.Body>
              <img src={orgchart} alt='Organizational Chart' className='img-fluid' />
            </Accordion.Body>
          </Card>
        </Accordion>
    </div>
  )
}

export default BsBiology
