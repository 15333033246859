import React from 'react';
import {Accordion, Card } from 'react-bootstrap';
import '../../../assets/style/Academe.css';
import drtref from '../../../assets/img/drtref.png';
import orgchart from '../../../assets/img/faculty-org.jpg';

export default function MaNursing() {
  return (
    <div className='container'>
        <div className='header-container'>
            <h1 style={{fontWeight: 'bold'}}>MASTER OF ARTS IN NURSING</h1>
            <h3>(Major in Nursing Education)</h3>
            <hr class="my-1"/>
        </div>
        <h4>OVERVIEW</h4>
        <p style={{textAlign: 'justify'}}>
          The Graduate Education in Nursing of the Remedios Trinidad Romualdez Educational Foundation addresses itself to develop effective and competent nurse-leaders who can carry out efficient health care delivery in the hospitals, community and other health care settings.
          Moreover, the Remedios Trinidad Romualdez Educational Foundation encourages graduate students to conduct researches in nursing geared towards the further development of the profession.
          The College offers Master of Art in Nursing, majors in Nursing Education and Nursing Administration. In order to meet the needs of the graduate students seeking admission in the graduate education program, it provides flexibility and adaptability to facilitate the operation of its curricular program.
          With the transmission and enrichment of the cultural heritage of the Filipino nurse, the Remedios Trinidad Romualdez Educational Foundation could indirectly extend meaningful health services to the people of Region VIII.
        </p>
        <div className='content'>
          <div className='col-10'>
            <h4>VISION</h4>
            <p>The RTRMF Graduate School at Tacloban City endeavors to become the center of excellence in Graduate Nursing Education, a model institution in education and research not only in Region VIII but also in the entire country.</p>
          </div>
          <div className='col-10'>
              <img id='imgnursing' src={drtref} alt=''/>
          </div>
        </div>
        <Accordion className='accordion-container mt-5'>
          <Card>
            <Accordion.Header>View Organizational Chart</Accordion.Header>
            <Accordion.Body>
              {/* <img src={orgchart} alt='Organizational Chart' className='img-fluid' /> */}
            </Accordion.Body>
          </Card>
        </Accordion>
    </div>
  )
}
