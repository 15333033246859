import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../../assets/style/Ejournal.css';

function MainPage() {
  const [researchPapers, setResearchPapers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchResearchPapers = async () => {
    try {
      const response = await axios.get("https://misadmin.drtrefi.edu.ph/api/journal?limit=10");

      if (response.data && response.data.results) {
        setResearchPapers(response.data.results);
      } else {
        console.error("API response is invalid:", response);
        setResearchPapers([]);
      }
    } catch (error) {
      console.error("API Error:", error);
      setError('Failed to fetch research papers.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResearchPapers();
  }, []);

  return (
    <div className='ejournal-container'>
      <header className='journal-header'>
        <h1 className='title' style={{fontWeight: 'bold',textAlign: 'center',marginBottom: '2rem'}}>Journal of Multidisciplinary Research in Health and Society</h1>
      </header>

      <section className='about'>
        <h2 className='section-title'>About the Journal</h2>
        <p className='section-text'>
          The Journal of Multidisciplinary Research in Health and Society is the official scholarly platform of Doña Remedios Trinidad Romualdez Educational Foundation that aims to publish quantitative and qualitative researches. It is a peer-reviewed journal dedicated to advancing multidimensional insights into the intricate relationships across various disciplines. Specifically, the journal actively seeks and publishes researches that encompass the realms of health sciences programs, criminology, law, entrepreneurship, and basic education. Original scientific investigations are highly encouraged to ensure dissemination of novel findings that contribute to intellectual discourse within these interconnected fields. In addition, the journal also welcomes contributions in the form of review articles, benchmark studies, insightful book reviews or chapters, and meta-analysis.
        </p>
        <p className='section-text'>
          By fostering a multidisciplinary perspective, the journal endeavors to bridge gaps between diverse areas of study, encouraging collaborative research that addresses important societal challenges and contributes to the holistic development of knowledge in health and society.
        </p>
      </section>

      <section className='volumes'>
        <h3 className='section-title'>Volume</h3>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <div className="jcontent row mt-4">
            {researchPapers.map((paper) => (
              paper && paper.pdf_filename ? (
                <div className='content-img col-md-4' key={paper.id}>
                  <img 
                    src={`https://misadmin.drtrefi.edu.ph/public/images/journals/${paper.image_filename}`} 
                    alt={`Cover of journal volume ${paper.volume}`} 
                    className='img-fluid journal-cover' 
                  />
                  <p className='volume-info'>
                    Volume {paper.volume} • <a target="_blank" rel="noreferrer" href={`https://misadmin.drtrefi.edu.ph/public/files/journals/${paper.pdf_filename}`} download>Download here</a>
                  </p>
                </div>
              ) : null
            ))}
          </div>
        )}
      </section>
    </div>
  );
}

export default MainPage;
