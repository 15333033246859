import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../../../../assets/style/OrgChart.css';

export default function ResearchOrg() {
  const [orgChartUrl, setOrgChartUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = 'https://misadmin.drtrefi.edu.ph/api/orgchart';
  const ORG_NAME = 'reseach-office-org';
  const DISPLAY_SITE = 'rtr';

  useEffect(() => {
    async function fetchOrgChart() {
      try {
        const response = await axios.get(`${API_URL}?orgname=${ORG_NAME}&display_site=${DISPLAY_SITE}`);
        const filename = response.data.results.image_filename;
        setOrgChartUrl(`https://misadmin.drtrefi.edu.ph/public/images/orgchart/${filename}`);
      } catch (error) {
        setError('Error fetching the organizational chart. Please try again later.');
        console.error('Error fetching the organizational chart:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchOrgChart();
  }, []);

  return (
    <div className="research-org-container">
      <header className="org-header">
        <h1>Research Office Chart</h1>
      </header>
      {loading ? (
        <p className="loading-text">Loading organizational chart...</p>
      ) : error ? (
        <p className="error-text">{error}</p>
      ) : (
        <img src={orgChartUrl} alt="Organizational Chart" className="org-chart-img" />
      )}
    </div>
  );
}
