import React from 'react';
import historyImage from '../../../assets/img/labhostipal.jpg'; 

const LabHospital = () => {
  return (
    <div className="history-container">
      <div className="history-about">
        <div className="history-header">
          <h1 style={{fontWeight: 'bold',textAlign: 'center'}}>DOÑA REMEDIOS TRINIDAD-ROMUALDEZ EDUCATIONAL FOUNDATION HOSPITAL</h1>
          <h4 style={{textAlign: 'center'}}>TACLOBAN CITY, PHILIPPINES</h4>
        </div>
        <h1 className='hist-drtr' style={{fontWeight: 'bold',textAlign: 'center'}}>HISTORY</h1>
        {/* History Details */}
        <div className="history-details text-justify leading-relaxed px-4 md:px-8 lg:px-16">
            <p className="mb-4">
                The Doña Remedios Trinidad-Romualdez Educational Foundation Hospital (DRTREFH) formerly (Remedios Trinidad Romualdez Hospital), was established in 1999 in response to the training needs of Doña Remedios Trinidad-Romualdez Educational Foundation, Inc. (DRTREFI), formerly (Doña Remedios Trinidad-Romualdez Medical Foundation, Inc), medical and paramedical students.  Outpatient consultation, radiographic and laboratory services started on March 17, 1999; on the other hand, in-patient admissions began in September of the same year.  Hospital License to Operate was issued by Department of Health as Level 2, new classification, (Tertiary Hospital) with 100 bed capacity and granted PhilHealth Accreditation as Center of Quality.  It is located along Calanipawan Road, Tacloban City, and the gateway to the largest housing subdivision of the city.
            </p>
            <p className="mb-4">
                The hospital is operated and maintained by the Doña Remedios Trinidad-Romualdez Educational Foundation, Inc., a non-stock, non-profit corporation with a Board of Trustees currently chaired by Mrs. Juliette Gomez Romualdez.
            </p>
            <p className="mb-4">
                Today, RTR Hospital is a level 2 (Tertiary Hospital) from 50 beds upgraded to 100 beds since 2014.
            </p>
            <image src={historyImage} alt="History of DRTREFI" className="w-full max-w-3xl mx-auto rounded-lg shadow-lg"/>
            {/* Image Section */}
            <div className="image-container">
            <img 
                src={historyImage} 
                alt="History of DRTREFI" 
                className="w-full max-w-3xl mx-auto rounded-lg shadow-lg"
            />
            </div>
            <p className="mb-4">
                The hospital clinical laboratory was given accreditation to accept Medical Technology Interns by the Department of Health.
            </p>
            <p className="mb-4">
                Currently, the hospital is housed in a two-storey concrete enclave with inner gardens conveniently connected to the new three-story modern building that will accommodate additional private rooms and other clinical services.  The Out-patient clinics are sprawled at the first floors of the connected concrete buildings, the hospital annex and the DRTREFI Building No. 5 with adequate parking spaces.
             </p>
            <p className="mb-4">
                The brand of services of DRTREF Hospital can be gleaned from its vision, mission and general policy.
            </p>
            <p className="mb-4" style={{fontWeight: 'bold'}}>
                CORE VALUES:  To give quality health services to all who seek its help.
            </p>
        </div>
      </div>
    </div>
  )
}

export default LabHospital
