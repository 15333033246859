import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../../assets/style/SinglePage.css';

const formatText = (str) => {
    if (!str) return '';
    return str
        .replace(/&nbsp;/g, ' ') // Replace &nbsp; with a space
        .replace(/\n/g, '<br />'); // Replace new lines with <br />
};

function SinglePage() {
    const { short_title } = useParams();
    const [article, setArticle] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios
            .get(`https://misadmin.drtrefi.edu.ph/api/articles/${short_title}?display_site=rtr`)
            .then(response => {
                const { results } = response.data;

                if (!results) {
                    setError('Failed to fetch journal details. Please try again later.');
                    return;
                }

                setArticle(results);
                setError(null);
            })
            .catch(error => {
                console.error('API Error:', error);
                setError('Failed to fetch journal details. Please try again later.');
                setArticle(null);
            });
    }, [short_title]);

    if (error) {
        return <h1 className="error-message">{error}</h1>;
    }

    if (!article) {
        return <h1 className="loading-message">Loading...</h1>;
    }

    // Destructure article data with default values
    const { 
        author = 'Unknown Author', 
        body: articleContent = 'Content is not available at the moment.', 
        cover_image, 
        title,
        tags = 'No tags available',
        created_at,
    } = article;

    // Format content for rendering
    const formattedArticleContent = formatText(articleContent);

    const formattedDate = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    }).format(new Date(created_at));

    return (
        <main>
            <div className="container">
                <section className="mainContent details">
                    <h1><b>{title || 'Untitled'}</b></h1>
                    <div className='date'>
                        <span>Published On: <b>{formattedDate}</b></span>
                    </div>
                    {cover_image ? (
                        <img src={cover_image} className='main-img' alt='Cover'/>
                    ) : (
                        <div className="img-placeholder">Image not available</div>
                    )}

                    <div className="contentSection" dangerouslySetInnerHTML={{ __html: formattedArticleContent }} />
                    <div className='author' style={{ marginTop: '20px' }}>
                        <b>Written By: {author}</b>
                    </div>
                    <b className="tags">Tags: {tags}</b>
                </section>
            </div>
        </main>
    );
}

export default SinglePage;
