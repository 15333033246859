import React from 'react';
import '../../../assets/style/ExtensionActivities.css';

function About() {
  return (
    <div className="about-container">
      <h1 style={{fontWeight: 'bold',textAlign: 'center'}}>DOÑA REMEDIOS TRINIDAD ROMUALDEZ EDUCATIONAL FOUNDATION INC.</h1>
      <h1 style={{fontWeight: 'bold',textAlign: 'center', marginTop: '30px'}}>Extension Service Office</h1>

      <section className="vision-mission">
        <h3><b>Vision</b></h3>
        <p>
          Foster a thriving community empowered by dynamic collaborative partnership and volunteerism through extension services, enhancing lives, providing holistic development, and creating sustainable impact.
        </p>

        <h3><b>Mission</b></h3>
        <p>
          Actively engage with the community, providing tailored extension services that address their unique needs, promote learning, facilitate social change, and build a stronger and more resilient community.
        </p>
      </section>

      <section className="goals-functions">
        <h3><b>Goal and Functions</b></h3>
        <p>
          The DRTR-EF Extension Service Office strives to implement relevant, effective, and sustainable programs and projects that will uplift the lives of the community people and the marginalized groups of society.
        </p>
        <ul>
          <li>Serves as overall coordinator of extension activities of the institution.</li>
          <li>Consolidate the extension plans of the different colleges.</li>
          <li>Implementation of policies, guidelines, and procedures relating to extension service.</li>
          <li>Conduct monitoring and evaluation of the implementation of extension projects.</li>
          <li>Evaluation and impact analyses of extension programs and projects implemented.</li>
          <li>Plan and implement capacity development programs for extension service providers.</li>
          <li>Establish linkages and partnerships with industries, government agencies, non-government organizations, and individuals.</li>
          <li>Serve as the central repository of documents relating to the extension service.</li>
          <li>Disseminate extension-related information through various platforms.</li>
          <li>Conduct studies, propose policies, processes, procedures, and mechanisms for the improvement and sustainability of the school’s programs and projects.</li>
        </ul>
      </section>

      <section className="agenda">
        <h3><b>Extension Services Agenda</b></h3>
        <p style={{textAlign: 'justify'}}>
          The extension services are not limited to the identified thrust and programs. The different Colleges/Departments are directed to prepare extension proposals that are consistent with the thrust of each College/Department or the institution as a whole, relevant to the immediate needs and interests of the target beneficiaries.
        </p>
        <p style={{textAlign: 'justify'}}>
          The research and extension office shall encourage peer or team extension programs, projects, and activities among the faculty, staff, and students. It also encourages collaboration and partnership with other line agencies in the implementation of different programs and projects in a form of cost sharing, tie-ups, and expertise sharing for the efficient and effective delivery of extension services towards the development of individuals and communities.
        </p>
      </section>

      <section className="possible-areas">
        <h3><b>Extension Agenda Possible Extension Areas</b></h3>
        <ul>
          <li>
            <strong>Education, Culture, and Human Capacity Development:</strong> Literacy; numeracy; skills development; value formation; personality development; culture.
          </li>
          <li>
            <strong>Health, Sanitation, and Nutrition:</strong> Reproductive health; family planning; maternal health; child care; promotion of healthy lifestyle; disease prevention; drug abuse; mental health; health service delivery; rehabilitation medicine; functional foods; health and wellness; sports.
          </li>
          <li>
            <strong>Economic Growth and Enterprise Development:</strong> Livelihood programs; business planning; financial literacy.
          </li>
          <li>
            <strong>Promotion of Human Rights, Equality, Justice, and Peace:</strong> Socio-Civic support; gender and development; advocacies against discrimination, violence, and crime; legal services.
          </li>
          <li>
            <strong>Environmental Protection, Climate Change Adaptation, and Disaster Risk Reduction:</strong> Management of natural resources and conservation for disaster risk reduction; risk assessment and management.
          </li>
        </ul>
      </section>
      <section className="short-term-agenda">
        <h3><b>Short Term Agenda</b></h3>
        <ul>
          <li>Medical Missions</li>
          <li>Blood Donation Drive</li>
          <li>Vegetable Gardening and Greening Program</li>
          <li>Relief Goods Operation</li>
          <li>Gift-Giving Activity</li>
          <li>Outreach program (Community, orphanages/prison camps/rehabilitation centers)</li>
          <li>Health Education Campaign</li>
        </ul>
      </section>

      <section className="extension-programs">
        <h3><b>Extension Programs and Services</b></h3>
        <p>
          The different colleges and departments of RTR-EF will plan and manage various extension projects and services aligned with their specific academic program mandates and the extension agenda, which can be channeled under the following extension programs:
        </p>

        <h4><b>Sustainable Community Development Program</b></h4>
        <p style={{textAlign: 'justify'}}>
          The primary aim of the program is to provide assistance to the community through the transfer of knowledge and skills and the integration of other necessary factors to come up with a holistic approach towards improving the quality of life of community people. The program provides assistance in identifying the needs of the community, which is a major factor in planning what programs and projects will be launched by the institution to cater to these needs. The community will serve as a model by which technical and non-technical assistance will be provided by the institution through its various extension services programs, implemented by different colleges and departments in collaboration with other agencies.
        </p>

        <h4><b>Support Services Program</b></h4>
        <ul>
          <li>
            <strong>Non-formal education:</strong> Short-term engagements such as health education, literacy and numeracy programs, training and workshops, and other relevant activities that cater to the needs of the community.
          </li>
          <li>
            <strong>Continuing education:</strong> Upgrade competencies of professionals and uplift the social, cultural, economic, and physical well-being and capabilities of the elderly, the youth, and other organizations such as cooperatives, people’s organizations, and others.
          </li>
          <li>
            <strong>Effective assistance:</strong> Provide expertise in addressing technical problems encountered and other immediate solutions to minor problems in the community.
          </li>
        </ul>

        <h4><b>Student Extension Experience Program</b></h4>
        <p>
          This program will be spearheaded by the different recognized student organizations of the institution. Leaders and members of the different organizations will be involved in planning, identifying, fund sourcing, and implementing extension initiatives in identified communities. It aims to inculcate the values of helping, commitment, and responsibility through practice. Specifically, it encourages student organizations' participation in community work, develops creativeness and resourcefulness in putting up community/sector-based development programs, and promotes healthy competition among student organizations through project development and implementation.
        </p>

        <h4><b>Special Extension Programs/Projects</b></h4>
        <p>
          This program is designed for activities that are not covered by the regular extension programs of the institution but serve sectors that are also clients of the extension office. It caters to activities falling under the short-term agenda of the institution and programs or projects where the institution has been tapped by external private or public agencies for implementation.
        </p>
      </section>
    </div>
  );
}

export default About;
