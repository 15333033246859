import React from 'react';
import carousel2 from '../../../assets/img/articles/bio2.jpg';
import '../../../assets/style/Articles.css';

function Article() {
  return (
    <div className='container'>
        <div className='header'>
            <h3>58TH BIOTA NATIONAL CONVENTION AND SCIENTIFIC SESSION</h3>
            <hr class="my-1"></hr>
        </div>
        <img className="rounded" src={carousel2} alt='' />
        <p>by: John Brandon Angelo D. Colinayo</p>
        <div className="subhead mt-3 flex flex-col">
          <p>
          Boracay, Aklan – April 18-20, 2024 – Faculty members of the Department of Medical Biology of Dona Remedios Trinidad Romualdez Educational Foundation recently participated in the prestigious 58th Biology Teachers Association of the Philippines (BIOTA) National Convention and Scientific Session held on April 18-20, 2024 at the picturesque Paradise Garden hotel in Boracay, Aklan. This event was a significant and attributive gathering of all the biology Educators and Researchers across the Philippines, focusing on the latest trends and technologies in Biological Research and Education. 
          The convention serves as an excellent manifesto for collaboration and knowledge in sharing the experiences and expertise of the different Biology Educators across the nation.  One of the key objectives was to discuss current trends in biology research that will lead to a better understanding of the different biological and scientific issues, fostering a community of educators dedicated to advancing the field. The conference included a meaningful discussion on various topics, beginning at the molecular biology level to ecological studies, highlighting the innovative research of different Biology Educators and Student researchers.
          The event's major focus was on Centralizing the immersive technologies in Biology Education. </p>
          <p>
          This gives meaningful knowledge to the attendees as well as an exploration of how virtual reality, augmented reality, and other cutting-edge technologies can be integrated into the classroom that will enhance the effectiveness of teaching and improve the student learning experiences where they can engage in more innovative and advanced discussion. In the generation of advanced technologies, these discussions were geared towards finding practical applications of this technology making Biology Education meaningful, artistic, and engaging for the students.
          Moreover, the convention featured different Scientific Research from different researchers where the Department of Medical Biology of DRTREF presented research findings. Notably, showcasing a paper of one of our Medical Biology alumnae, garnering significant attention from peers and experts alike. The presentation highlights to every attendee the high quality of research produced by the department’s alumni and current students, proof that the institution has well-competent researchers that can be able to compete with other schools. 
          The Participation of the faculty members in this prestigious and significant event was made possible by the support of the institution believing that this will play an important key in improving the Research and Biology Education to provide competent graduates in this field. This support also enabled the faculty to engage fully in the convention, contributing to and benefiting from the wealth of knowledge and networking opportunities available. 
          Indeed, the 58th Biota National Convention and Scientific Session was a resounding success, promoting collaboration, innovation, and the advancement of Biology Education and Research. The faculty members returned with renewed inspiration and practical insights to implement immersive technologies and enhance the learning experiences of their students.
          </p>
          <p>
          Introduction: Identifies the parties involved and the purpose of the agreement.
          Background: Provides context and rationale for the collaboration.
          Objective: Clearly states the goals and objectives of the agreement.
          Scope of Work: Details the specific activities and responsibilities of each party.
          Duration: Specifies the timeframe for the agreement, including start and end dates.
          Resources and Funding: Outlines the resources, funding, and financial contributions of each party.
          Governance and Management: Describes the management structure and decision-making processes.
          Reporting and Evaluation: Defines the reporting requirements and evaluation methods.
          Confidentiality and Intellectual Property: Addresses confidentiality issues and the handling of intellectual property.
          Amendments and Termination: Provides procedures for amending or terminating the agreement.
          Signatures: Includes the signatures of authorized representatives from each party.
          </p>
          <p>FB Page: RTRMF Biology Department</p>
          <p>Cellphone Number: 0916-445-2234</p>
          <p>Email: RTRBIOLOGY@GMAIL.COM</p>
        </div>
    </div>
  )
}

export default Article
