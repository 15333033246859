import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../../assets/style/Journal.css';
import axios from 'axios';

const Journal = () => {
  const [researchPapers, setResearchPapers] = useState([]);

  useEffect(() => {
    const fetchResearchPapers = async () => {
      try {
        const response = await axios.get("https://misadmin.drtrefi.edu.ph/api/research", { params: { limit: 10 } });
        setResearchPapers(response.data.results);
      } catch (error) {
        console.error("API Error:", error);
        setResearchPapers([]);
      }
    };
    fetchResearchPapers();
  }, []);

  const stripHtmlTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, "");

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.slice(0, wordLimit).join(" ") + (words.length > wordLimit ? "..." : "");
  };

  const renderResearchPapers = researchPapers.map(({ id, title, authors, published_on, abstract }) => (
    <article className="post" key={id}>
      <div className="entry-wrapper">
        <header className="entry-header">
          <h2 className="title-journal">
            <Link to={`/research/faculty-personnel-research/${id}`} target="_self">
              {title || "Untitled"}
            </Link>
          </h2>
          <div className="entry-meta">
            <i className="fa fa-user" aria-hidden="true" />
            &nbsp;Author/s Name:&nbsp;
            {Array.isArray(authors) && authors.length > 0 
              ? authors.map((author) => author.authors.fullName).join(", ") 
              : "Unknown"}
            <p>Published on: {published_on || "Unknown"}</p>
          </div>
        </header>
        <div className="entry-content">
          <p>
            {truncateText(stripHtmlTags(abstract || ""), 100)}
            {abstract && (
              <Link className="moretag" to={`/research/faculty-personnel-research/${id}`}>
                Click to Continue reading...
              </Link>
            )}
          </p>
        </div>
      </div>
    </article>  
  ));

  return (
      <div className="main-content">
        <h2>FACULTY AND PERSONNEL RESEARCH</h2>
        <hr className="divider" />
        <div className="journal-content">
          <div className="post-box">
            {renderResearchPapers}
          </div>
        </div>
      </div>
  );
};

export default Journal;
