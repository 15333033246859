import React from 'react';
import { useEffect, useState } from 'react';
import { Carousel, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import coverpage from '../../assets/img/Cover.png';
import '../../assets/style/CarouselStyle.css';
import axios from 'axios';

const MainCarousel = React.memo(() => {
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [banners, setBanners] = useState([]);

  const baseUrl = "https://misadmin.drtrefi.edu.ph/public/images/banners/";

  useEffect(() => {
    const fetchBannersFromApi = async () => {
      const url = `https://misadmin.drtrefi.edu.ph/api/banner?display_site=rtr&limit=7`;

      try {
        const response = await axios.get(url);
        setBanners(response.data.results || []);
      } catch (error) {
        console.error(`Error fetching banners from ${url}`, error);
      }
    };

    const intervalId = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    fetchBannersFromApi();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className="cover d-flex align-items-center justify-content-between">
        <div className="logo">
          <img src={coverpage} alt="Cover" />
        </div>
        <div className="real-time">
          Philippine Standard Time: &nbsp;&nbsp; {currentTime}
        </div>
      </div>
      <Carousel fade>
        {banners.length === 0 ? (
          <Carousel.Item>
            <div className="carousel-placeholder"></div>
          </Carousel.Item>
        ) : (
          banners.map((banner, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={`${baseUrl}${banner.banner_image}`}
                alt={`Slide ${index + 1}`}
                loading="lazy"
              />
            </Carousel.Item>
          ))
        )}
      </Carousel>
    </>
  );
});

export default MainCarousel;