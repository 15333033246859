import React, { useEffect, useState } from 'react';
import ActivityCard from './ActivityCard'; // Ensure this path is correct
import '../../../assets/style/ExtensionActivities.css';

const ExtensionActivities = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchActivities = async () => {
      const API_URL = 'https://misadmin.drtrefi.edu.ph/api/activities?display_page=linkages&limit=20';
      try {
        const response = await fetch(API_URL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched data:", data);
        setActivities(data.activities || []);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };

    fetchActivities();
  }, []);

  return (
    <div className="extension-activities">
      <header className="extension-activities_header">
        <h1 className="extension-activities_title">Extension Services Activities</h1>
        <p className="extension-activities_description">
          Engage with our community through various outreach programs and activities.
        </p>
      </header>

      <section className="extension-activities_list">
        {activities.map((activity) => (
          <ActivityCard
            key={activity.folder_id}
            image={activity.activity_image}
            title={activity.title}
            description={activity.activity_desc}
          />
        ))}
      </section>

      <section className="extension-activities_upcoming">
        <h2 className="extension-activities_section-title">Upcoming Events</h2>
        {/* Add upcoming events content here */}
      </section>

      <section className="extension-activities_past">
        <h2 className="extension-activities_section-title">Past Activities</h2>
        {/* Add past activities content here */}
      </section>
    </div>
  );
};

export default ExtensionActivities;
