import React from 'react';
import '../../../../assets/style/Admission.css';
import { Accordion, Table } from 'react-bootstrap';

function MedTech() {
  return (
    <div className='admission-container'>
        <div className='ad-header'>
            <h1 style={{textAlign:'left', fontSize:'37px'}}><b>BACHELOR OF SCIENCE IN MEDICAL LABORATORY SCIENCE</b></h1>
            <hr class="my-1"></hr>
        </div>
        <div className='admission-req flow'>
            <h3><b>ADMISSION REQUIREMENTS</b></h3>
            <p>An applicant for admission to the course must satisfy the following requirements:</p>
            <p>1. Must be a high school graduate from any accredited school with a general weighted average of 80% or better</p>
            <p>2. Must have passed the entrance examination and personal interview</p>
            <p>3. Must be of good moral character</p>
            <p>4. Physically fit and preferably not more than 30 years of age.</p>
            <p>5. Shiftee from other courses within the institution is accepted provided he/she has a satisfactory scholastic performance from the previous College attended.</p>
            <p>6. Transferee from other HEI’s are also accepted provided he/she has a good scholastic performance from previous school last attended and passed the Entrance examination and interview</p>
            
            <p>No applicant shall be admitted for registration unless he/she has submitted the 	following credentials:</p>
            <p>1. Form 138 (High School Report Card) or its equivalent</p>
            <p>2. Birth certificate (NSO certified True Copy)</p>
            <p>3. Certificate of good moral character from the last school attended</p>
            <p>4. Medical Certificate issued by RTR-MF designated physician</p>
            <p>5. For shifty, Certified true copy of grades from the Registrar’s office</p>
            <p>6. For transferee’s, TOR and Honorable Dismissal from the previous School  </p>
        </div>
        <div className="subhead-ad">
            <hr class="my-5"></hr>
            <h4><b>CURRICULUM</b></h4>
            <span>Per CMO No. 13 s. 2017 (Effective AY: 2018 - 2019)</span>
            <br/>
        </div>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FIRST YEAR</Accordion.Header>
                <Accordion.Body>
                <h7>FIRST SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th>Course Code</th>
                        <th>Course</th>
                        <th>Lec</th>
                        <th>Lab</th>
                        <th>Units</th>
                        <th>Pre-Requisites</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>CHEM 1</th>
                        <td>Inorganic & Organic Chemistry</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>4</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>ANA 1</th>
                        <td>Human Anatomy & Physiology with Pathophysiology</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>4</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>PMLS 1</th>
                        <td>Principles of Medical Laboratory Science Practice 1 (Intro to Med Lab Science, Lab Safety and Waste Management)</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>HIS</th>
                        <td>Health Information System for Med Lab Science</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>1</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE 1113</th>
                        <td>Purposive Communication</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE 1123</th>
                        <td>Mathematics in the Modern World</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>P.E. 1</th>
                        <td>PathFit 1</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>NSTP. 1</th>
                        <td>NSTP 1</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                </tbody>
                </Table>
                <h7>SECOND SEMESTER</h7>
                <Table class="table" size="sm" bordered>
                <thead>
                    <tr>
                        <th>Course Code</th>
                        <th>Course</th>
                        <th>Lec</th>
                        <th>Lab</th>
                        <th>Units</th>
                        <th>Pre-Requisites</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>CHEM 2</th>
                        <td>Analytical Chemistry (Qualitative & Quantitative Chemistry)</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>4</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td>CHEM 1</td>
                    </tr>
                    <tr>
                        <th>PMLS 2</th>
                        <td>Principles of Medical Laboratory Science Practice 2 ( Clinical Laboratory Assistance and Phlebotomy)</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>1</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td>PMLS 1</td>
                    </tr>
                    <tr>
                        <th>GE 2243</th>
                        <td>Environmental Science</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE 1213</th>
                        <td>Understanding the Self/Psychology</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>GE 1223</th>
                        <td>Ethics</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>STS</th>
                        <td>Science, Technology & Society</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>P.E. 2</th>
                        <td>PathFit 2</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>2</td>
                        <td>P.E. 1</td>
                    </tr>
                    <tr>
                        <th>NSTP 2</th>
                        <td>NSTP 2</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>0</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td>NSTP 1</td>
                    </tr>
                    <tr className='subtotal'>
                        <td colSpan={2}>Sub-Total:</td>
                        <td style={{textAlign:'center'}}>21</td>
                        <td style={{textAlign:'center'}}>3</td>
                        <td style={{textAlign:'center'}}>24</td>
                        <td></td>
                    </tr>
                    <br/>
                </tbody>
                </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>SECOND YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th scope="col">Course Code</th>
                            <th scope="col">Course</th>
                            <th scope="col">Lec</th>
                            <th scope="col">Lab</th>
                            <th scope="col">Units</th>
                            <th scope="col">Pre-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>CHEM 3</th>
                            <td>Biochemistry for Med Lab Science</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>CHEM 1</td>
                        </tr>
                        <tr>
                            <th>GE 2113</th>
                            <td>Biostatics and Epidemiology</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>PMLS 1</td>
                        </tr>
                        <tr>
                            <th>H.C.</th>
                            <td>Community and Public Health for Med Laboratory Science</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>MT 217</th>
                            <td>Laboratory Management</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 2123</th>
                            <td>The Contemporary World</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 213</th>
                            <td>Life and Works of Rizal</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 2253</th>
                            <td>Logic</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>P.E. 3</th>
                            <td>PathFit 3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>P.E. 1 & 2</td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td style={{textAlign:'center'}}>20</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td style={{textAlign:'center'}}>25</td>
                            <td></td>
                        </tr>
                        <br/>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th>Course Code</th>
                            <th>Course</th>
                            <th>Lec</th>
                            <th>Lab</th>
                            <th>Units</th>
                            <th>Pre-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>MT 213</th>
                            <td>Human Histology</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>ANA 1</td>
                        </tr>
                        <tr>
                            <th>MT 214</th>
                            <td>Cytogenetics</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>ANA 1, CHEM 3</td>
                        </tr>
                        <tr>
                            <th>MT 215</th>
                            <td>Clinical Parasitology</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>ANA 1</td>
                        </tr>
                        <tr>
                            <th>MT 216</th>
                            <td>Medical Technology Laws and Bioethic</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>PMLS 1</td>
                        </tr>
                        <tr>
                            <th>MT 218</th>
                            <td>Principles and Strategies of Teaching in Med Laboratory Science</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 2213</th>
                            <td>Readings in Philippine History</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 2223</th>
                            <td>Art Appreciation</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>GE 2233</th>
                            <td>Philippine Literature in English</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>P.E. 4</th>
                            <td>PathFit 4</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td>21</td>
                            <td>2</td>
                            <td>23</td>
                            <td></td>
                        </tr>
                        <br/>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>THIRD YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th>Course Code</th>
                            <th>Course</th>
                            <th>Lec</th>
                            <th>Lab</th>
                            <th>Units</th>
                            <th>Pre-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>MT 311</th>
                            <td>Clinical Chemistry</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>ANA 1, CHEM 3</td>
                        </tr>
                        <tr>
                            <th>MT 312</th>
                            <td>Clinical Bacteriology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>ANA 1, CHEM 3</td>
                        </tr>
                        <tr>
                            <th>MT 323</th>
                            <td>Immunology & Serology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>ANA 1, CHEM 3</td>
                        </tr>
                        <tr>
                            <th>MT 314</th>
                            <td>Hematology 1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>ANA 1</td>
                        </tr>   
                        <tr>
                            <th>MT 315</th>
                            <td>Histopathologic & Cytologic Techniques</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>MT 213</td>
                        </tr>
                        <tr>
                            <th>MT 316</th>
                            <td>Intro to Med Laboratory Science Research</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>                
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td>15</td>
                            <td>8</td>
                            <td>23</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th>Course Code</th>
                            <th>Course</th>
                            <th>Lec</th>
                            <th>Lab</th>
                            <th>Units</th>
                            <th>Pre-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>MT 321</th>
                            <td>Clinical Chemistry 2</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>5</td>
                            <td>MT 311</td>
                        </tr>
                        <tr>
                            <th>MT 322</th>
                            <td>Mycology & Virology</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td>MT 312</td>
                        </tr>
                        <tr>
                            <th>MT 324</th>
                            <td>Hematology 2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>MT 314</td>
                        </tr>
                        <tr>
                            <th>MT 325</th>
                            <td>Immunohematology</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>4</td>
                            <td>MT 323, MT 214</td>
                        </tr>   
                        <tr>
                            <th>MT 313</th>
                            <td>Analysis of Urine and Body Fluids</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>ANA 1, CHEM 3</td>
                        </tr>
                        <tr>
                            <th>MT 326</th>
                            <td>Med Laboratory Science Research Paper Writing and Presentation</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>MT 316</td>
                        </tr>
                        <tr>
                            <th>MT 327</th>
                            <td>Molecular Biology and Diagnostics</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>3</td>
                            <td>MT 214, CHEM 3</td>
                        </tr>                 
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td>14</td>
                            <td>9</td>
                            <td>23</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header>FOURTH YEAR</Accordion.Header>
                <Accordion.Body>
                    <h7>FIRST SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th>Course Code</th>
                            <th>Course</th>
                            <th>Lec</th>
                            <th>Lab</th>
                            <th>Units</th>
                            <th>Pre-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>MT 411</th>
                            <td>Clinical Internship 1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>12</td>
                            <td style={{textAlign:'center'}}>12</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>MT 412</th>
                            <td>Seminar 1</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>MT 413</th>
                            <td>Medical Technology Assessment Program 1</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>               
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td>3</td>
                            <td>12</td>
                            <td>15</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                    <h7>SECOND SEMESTER</h7>
                    <Table class="table" size="sm" bordered>
                    <thead>
                        <tr>
                            <th>Course Code</th>
                            <th>Course</th>
                            <th>Lec</th>
                            <th>Lab</th>
                            <th>Units</th>
                            <th>Pre-Requisites</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>MT 421</th>
                            <td>Clinical internship 2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>12</td>
                            <td style={{textAlign:'center'}}>12</td>
                            <td>MT 411</td>
                        </tr>
                        <tr>
                            <th>MT 422</th>
                            <td>Seminar 2</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>1</td>
                            <td>MT 412</td>
                        </tr>
                        <tr>
                            <th>MT 423</th>
                            <td>Medical Technology Assessment Program 2</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td style={{textAlign:'center'}}>0</td>
                            <td style={{textAlign:'center'}}>2</td>
                            <td></td>
                        </tr>               
                        <tr className='subtotal'>
                            <td colSpan={2}>Sub-Total:</td>
                            <td>3</td>
                            <td>12</td>
                            <td>15</td>
                            <td></td>
                        </tr>
                    </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
  )
}

export default MedTech
