import React from 'react';
import '../../../../assets/style/Journal.css'

function Guidelines() {
  return (
    <div className='container'>
        <div className='header'>
            <h3>GUIDELINES</h3>
            <hr class="my-1"></hr>
        </div>
        <div className="subhead mt-3 flex flex-col">
            <h4>NO DETAILS</h4>
        </div>
    </div>
  )
}

export default Guidelines
