import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../../assets/style/Journal.css';

function JournalContent() {
  const { id: journalId } = useParams();
  const [journalDetails, setJournalDetails] = useState(null);
  const [pdfFileUrl, setPdfFileUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJournalDetails = async () => {
      try {
        const { data } = await axios.get(`https://misadmin.drtrefi.edu.ph/api/research/${journalId}`);
        const { pdf_filename: pdfFilename, ...journal } = data.results;
        setJournalDetails(journal);

        if (pdfFilename) {
          setPdfFileUrl(`https://misadmin.drtrefi.edu.ph/public/files/researches/${pdfFilename}`);
        }

        setError(null);
      } catch (error) {
        setError('Failed to fetch journal details. Please try again later.');
        setJournalDetails(null);
      }
    };

    fetchJournalDetails();
  }, [journalId]);

  if (error) return <div>{error}</div>;
  if (!journalDetails) return <div>Loading...</div>;

  return (
      <div className="journal-detail">
        <h1 className='title-journal'>{journalDetails.title || "No Title Available"}</h1>
        <div className="journal-meta">
          <span>
            Author/s: {journalDetails.authors.length > 0 ? (
              journalDetails.authors.map(({ authors: { fullName } }) => fullName).join(", ")
            ) : (
              "Unknown"
            )}
          </span>
          <span>Published on: {journalDetails.published_on || "Unknown Date"}</span>
        </div>
        <div className="journal-content">
          <div
            dangerouslySetInnerHTML={{
              __html: journalDetails.abstract || "No abstract available.",
            }}
          />
        </div>
        {pdfFileUrl ? (
          <object
            className="pdf"
            data={pdfFileUrl}
            type="application/pdf"
            width="100%"
            height="600px"
          >
            <p>
              Your browser does not support PDFs. <a href={pdfFileUrl}>Download the PDF</a>.
            </p>
          </object>
        ) : (
          <p>PDF not available.</p>
        )}
      </div>
  );
}

export default JournalContent;
